//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


if (typeof User === 'undefined') {
  User = {};
}
User.UserRole = {
  'ADMIN' : 1,
  'USER' : 100,
  'EXPERIED' : 1000,
  'LOCKED' : 1001,
  'INVALID' : 1002
};
User.LoginProvider = {
  'GOOGLE' : 1,
  'FB' : 2,
  'TWITTER' : 3,
  'LINKEDID' : 4,
  'ORCID' : 5,
  'INFINITCODEX' : 100
};
User.UserFlow = {
  'SIGNUP_REQUIRED' : 1,
  'SIGNUP_COMPLETED' : 2,
  'BC_USER_REGISTER_PENDING' : 3,
  'BC_USER_REGISTER_COMPLETED' : 4,
  'COMPLETED' : 100,
  'LOCKED' : 1000,
  'INVALID' : 1001
};
User.UserProfile = {
  'RESEARCHER' : 1,
  'PUBLISHER' : 2,
  'JOURNALIST' : 4,
  'MARKETING' : 8,
  'ADMIN' : 2048
};
User.SessionType = {
  'CURRENT' : 1,
  'HISTORY' : 2,
  'FAILED' : 3
};
User.FollowStatus = {
  'FOLLOWING' : 1,
  'UNFOLLOWING' : 2,
  'FOLLOWING_NOT_ALLOWED' : 4,
  'INVALID_USER_ID' : 8,
  'INVALID_FOLLOW_USER_ID' : 16
};
User.ActionType = {
  'ADD_USER' : 1,
  'UPDATE_USER' : 2,
  'ADD_REVIEW' : 100000,
  'UPDATE_REVIEW' : 100001,
  'COMPLETE_REVIEW' : 100002,
  'ADD_COMMENT' : 100003,
  'UPDATE_COMMENT' : 100004,
  'ADD_ARTICLE' : 100006,
  'UPDATE_ARTICLE' : 100007,
  'COMPLETE_ARTICLE' : 100008,
  'CLAIM_ARTICLE' : 100009,
  'ENTITY_LIKE' : 100100,
  'ENTITY_DISLIKE' : 100101
};
User.NotificationType = {
  'ENTITY_COMMENT' : 1,
  'ENTITY_LIKE' : 2,
  'ENTITY_DISLIKE' : 3,
  'INVITATION_TO_CLAIM' : 1000,
  'INVITATION_TO_CHALLANGE' : 1001,
  'CHALLANGE_COMPLETED' : 5000,
  'REQ_FOR_REVIEW' : 10000
};
User.User = function(args) {
  this.userId = null;
  this.role = null;
  this.firstName = null;
  this.lastName = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.lockedTime = null;
  this.active = null;
  this.confirmed = null;
  this.phone = null;
  this.layoutSettings = null;
  this.flow = null;
  this.pictureUrl = null;
  this.profile = null;
  this.fos = null;
  this.keywords = null;
  this.bcAddress = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.role !== undefined && args.role !== null) {
      this.role = args.role;
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.lockedTime !== undefined && args.lockedTime !== null) {
      this.lockedTime = args.lockedTime;
    }
    if (args.active !== undefined && args.active !== null) {
      this.active = args.active;
    }
    if (args.confirmed !== undefined && args.confirmed !== null) {
      this.confirmed = args.confirmed;
    }
    if (args.phone !== undefined && args.phone !== null) {
      this.phone = args.phone;
    }
    if (args.layoutSettings !== undefined && args.layoutSettings !== null) {
      this.layoutSettings = args.layoutSettings;
    }
    if (args.flow !== undefined && args.flow !== null) {
      this.flow = args.flow;
    }
    if (args.pictureUrl !== undefined && args.pictureUrl !== null) {
      this.pictureUrl = args.pictureUrl;
    }
    if (args.profile !== undefined && args.profile !== null) {
      this.profile = args.profile;
    }
    if (args.fos !== undefined && args.fos !== null) {
      this.fos = Thrift.copyList(args.fos, [null]);
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
  }
};
User.User.prototype = {};
User.User.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.role = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I64) {
        this.lockedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.BOOL) {
        this.active = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.BOOL) {
        this.confirmed = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.phone = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.layoutSettings = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.I32) {
        this.flow = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.pictureUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.I32) {
        this.profile = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.fos = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = input.readString().value;
          this.fos.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.LIST) {
        var _size7 = 0;
        var _rtmp311;
        this.keywords = [];
        var _etype10 = 0;
        _rtmp311 = input.readListBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = input.readString().value;
          this.keywords.push(elem13);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.User.prototype.write = function(output) {
  output.writeStructBegin('User');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.role !== null && this.role !== undefined) {
    output.writeFieldBegin('role', Thrift.Type.I32, 3);
    output.writeI32(this.role);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 4);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 5);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 6);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 7);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.lockedTime !== null && this.lockedTime !== undefined) {
    output.writeFieldBegin('lockedTime', Thrift.Type.I64, 8);
    output.writeI64(this.lockedTime);
    output.writeFieldEnd();
  }
  if (this.active !== null && this.active !== undefined) {
    output.writeFieldBegin('active', Thrift.Type.BOOL, 9);
    output.writeBool(this.active);
    output.writeFieldEnd();
  }
  if (this.confirmed !== null && this.confirmed !== undefined) {
    output.writeFieldBegin('confirmed', Thrift.Type.BOOL, 10);
    output.writeBool(this.confirmed);
    output.writeFieldEnd();
  }
  if (this.phone !== null && this.phone !== undefined) {
    output.writeFieldBegin('phone', Thrift.Type.STRING, 11);
    output.writeString(this.phone);
    output.writeFieldEnd();
  }
  if (this.layoutSettings !== null && this.layoutSettings !== undefined) {
    output.writeFieldBegin('layoutSettings', Thrift.Type.STRING, 12);
    output.writeString(this.layoutSettings);
    output.writeFieldEnd();
  }
  if (this.flow !== null && this.flow !== undefined) {
    output.writeFieldBegin('flow', Thrift.Type.I32, 14);
    output.writeI32(this.flow);
    output.writeFieldEnd();
  }
  if (this.pictureUrl !== null && this.pictureUrl !== undefined) {
    output.writeFieldBegin('pictureUrl', Thrift.Type.STRING, 15);
    output.writeString(this.pictureUrl);
    output.writeFieldEnd();
  }
  if (this.profile !== null && this.profile !== undefined) {
    output.writeFieldBegin('profile', Thrift.Type.I32, 16);
    output.writeI32(this.profile);
    output.writeFieldEnd();
  }
  if (this.fos !== null && this.fos !== undefined) {
    output.writeFieldBegin('fos', Thrift.Type.LIST, 17);
    output.writeListBegin(Thrift.Type.STRING, this.fos.length);
    for (var iter14 in this.fos)
    {
      if (this.fos.hasOwnProperty(iter14))
      {
        iter14 = this.fos[iter14];
        output.writeString(iter14);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 18);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter15 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter15))
      {
        iter15 = this.keywords[iter15];
        output.writeString(iter15);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 19);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GenerateAccountMergeHashReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.GenerateAccountMergeHashReq.prototype = {};
User.GenerateAccountMergeHashReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GenerateAccountMergeHashReq.prototype.write = function(output) {
  output.writeStructBegin('GenerateAccountMergeHashReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GenerateAccountMergeHashResp = function(args) {
  this.userId = null;
  this.accountMergeHash = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.accountMergeHash !== undefined && args.accountMergeHash !== null) {
      this.accountMergeHash = args.accountMergeHash;
    }
  }
};
User.GenerateAccountMergeHashResp.prototype = {};
User.GenerateAccountMergeHashResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.accountMergeHash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GenerateAccountMergeHashResp.prototype.write = function(output) {
  output.writeStructBegin('GenerateAccountMergeHashResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.accountMergeHash !== null && this.accountMergeHash !== undefined) {
    output.writeFieldBegin('accountMergeHash', Thrift.Type.STRING, 2);
    output.writeString(this.accountMergeHash);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.LoginReq = function(args) {
  this.email = null;
  this.passwd = null;
  this.ipAddr = null;
  this.role = null;
  this.provider = null;
  this.idToken = null;
  this.accountMergeHash = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.passwd !== undefined && args.passwd !== null) {
      this.passwd = args.passwd;
    }
    if (args.ipAddr !== undefined && args.ipAddr !== null) {
      this.ipAddr = args.ipAddr;
    }
    if (args.role !== undefined && args.role !== null) {
      this.role = args.role;
    }
    if (args.provider !== undefined && args.provider !== null) {
      this.provider = args.provider;
    }
    if (args.idToken !== undefined && args.idToken !== null) {
      this.idToken = args.idToken;
    }
    if (args.accountMergeHash !== undefined && args.accountMergeHash !== null) {
      this.accountMergeHash = args.accountMergeHash;
    }
  }
};
User.LoginReq.prototype = {};
User.LoginReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.passwd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.ipAddr = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.role = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I32) {
        this.provider = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.idToken = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.accountMergeHash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.LoginReq.prototype.write = function(output) {
  output.writeStructBegin('LoginReq');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.passwd !== null && this.passwd !== undefined) {
    output.writeFieldBegin('passwd', Thrift.Type.STRING, 2);
    output.writeString(this.passwd);
    output.writeFieldEnd();
  }
  if (this.ipAddr !== null && this.ipAddr !== undefined) {
    output.writeFieldBegin('ipAddr', Thrift.Type.STRING, 3);
    output.writeString(this.ipAddr);
    output.writeFieldEnd();
  }
  if (this.role !== null && this.role !== undefined) {
    output.writeFieldBegin('role', Thrift.Type.I32, 4);
    output.writeI32(this.role);
    output.writeFieldEnd();
  }
  if (this.provider !== null && this.provider !== undefined) {
    output.writeFieldBegin('provider', Thrift.Type.I32, 5);
    output.writeI32(this.provider);
    output.writeFieldEnd();
  }
  if (this.idToken !== null && this.idToken !== undefined) {
    output.writeFieldBegin('idToken', Thrift.Type.STRING, 6);
    output.writeString(this.idToken);
    output.writeFieldEnd();
  }
  if (this.accountMergeHash !== null && this.accountMergeHash !== undefined) {
    output.writeFieldBegin('accountMergeHash', Thrift.Type.STRING, 7);
    output.writeString(this.accountMergeHash);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.LoginResp = function(args) {
  this.user = null;
  this.error = null;
  this.sessionTokenExpirationTime = null;
  this.provider = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new User.User(args.user);
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = args.error;
    }
    if (args.sessionTokenExpirationTime !== undefined && args.sessionTokenExpirationTime !== null) {
      this.sessionTokenExpirationTime = args.sessionTokenExpirationTime;
    }
    if (args.provider !== undefined && args.provider !== null) {
      this.provider = args.provider;
    }
  }
};
User.LoginResp.prototype = {};
User.LoginResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new User.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.error = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.sessionTokenExpirationTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.provider = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.LoginResp.prototype.write = function(output) {
  output.writeStructBegin('LoginResp');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRING, 2);
    output.writeString(this.error);
    output.writeFieldEnd();
  }
  if (this.sessionTokenExpirationTime !== null && this.sessionTokenExpirationTime !== undefined) {
    output.writeFieldBegin('sessionTokenExpirationTime', Thrift.Type.I64, 3);
    output.writeI64(this.sessionTokenExpirationTime);
    output.writeFieldEnd();
  }
  if (this.provider !== null && this.provider !== undefined) {
    output.writeFieldBegin('provider', Thrift.Type.I32, 4);
    output.writeI32(this.provider);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Logout = function(args) {
  this.reason = null;
  if (args) {
    if (args.reason !== undefined && args.reason !== null) {
      this.reason = args.reason;
    }
  }
};
User.Logout.prototype = {};
User.Logout.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.reason = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Logout.prototype.write = function(output) {
  output.writeStructBegin('Logout');
  if (this.reason !== null && this.reason !== undefined) {
    output.writeFieldBegin('reason', Thrift.Type.STRING, 1);
    output.writeString(this.reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetSessionReq = function(args) {
  this.type = null;
  this.dates = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.dates !== undefined && args.dates !== null) {
      this.dates = Thrift.copyList(args.dates, [null]);
    }
  }
};
User.GetSessionReq.prototype = {};
User.GetSessionReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.dates = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = input.readString().value;
          this.dates.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetSessionReq.prototype.write = function(output) {
  output.writeStructBegin('GetSessionReq');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.dates !== null && this.dates !== undefined) {
    output.writeFieldBegin('dates', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRING, this.dates.length);
    for (var iter23 in this.dates)
    {
      if (this.dates.hasOwnProperty(iter23))
      {
        iter23 = this.dates[iter23];
        output.writeString(iter23);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Session = function(args) {
  this.id = null;
  this.userId = null;
  this.createdDate = null;
  this.createdTime = null;
  this.closedTime = null;
  this.ipAddr = null;
  this.location = null;
  this.reason = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.createdDate !== undefined && args.createdDate !== null) {
      this.createdDate = args.createdDate;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.closedTime !== undefined && args.closedTime !== null) {
      this.closedTime = args.closedTime;
    }
    if (args.ipAddr !== undefined && args.ipAddr !== null) {
      this.ipAddr = args.ipAddr;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.reason !== undefined && args.reason !== null) {
      this.reason = args.reason;
    }
  }
};
User.Session.prototype = {};
User.Session.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.createdDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.closedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.ipAddr = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.reason = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Session.prototype.write = function(output) {
  output.writeStructBegin('Session');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 2);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.createdDate !== null && this.createdDate !== undefined) {
    output.writeFieldBegin('createdDate', Thrift.Type.STRING, 3);
    output.writeString(this.createdDate);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 4);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.closedTime !== null && this.closedTime !== undefined) {
    output.writeFieldBegin('closedTime', Thrift.Type.I64, 5);
    output.writeI64(this.closedTime);
    output.writeFieldEnd();
  }
  if (this.ipAddr !== null && this.ipAddr !== undefined) {
    output.writeFieldBegin('ipAddr', Thrift.Type.STRING, 6);
    output.writeString(this.ipAddr);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 7);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.reason !== null && this.reason !== undefined) {
    output.writeFieldBegin('reason', Thrift.Type.STRING, 8);
    output.writeString(this.reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetSessionResp = function(args) {
  this.sessions = null;
  if (args) {
    if (args.sessions !== undefined && args.sessions !== null) {
      this.sessions = Thrift.copyList(args.sessions, [User.Session]);
    }
  }
};
User.GetSessionResp.prototype = {};
User.GetSessionResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size24 = 0;
        var _rtmp328;
        this.sessions = [];
        var _etype27 = 0;
        _rtmp328 = input.readListBegin();
        _etype27 = _rtmp328.etype;
        _size24 = _rtmp328.size;
        for (var _i29 = 0; _i29 < _size24; ++_i29)
        {
          var elem30 = null;
          elem30 = new User.Session();
          elem30.read(input);
          this.sessions.push(elem30);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetSessionResp.prototype.write = function(output) {
  output.writeStructBegin('GetSessionResp');
  if (this.sessions !== null && this.sessions !== undefined) {
    output.writeFieldBegin('sessions', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.sessions.length);
    for (var iter31 in this.sessions)
    {
      if (this.sessions.hasOwnProperty(iter31))
      {
        iter31 = this.sessions[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.UpdateUser = function(args) {
  this.user = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new User.User(args.user);
    }
  }
};
User.UpdateUser.prototype = {};
User.UpdateUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new User.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.UpdateUser.prototype.write = function(output) {
  output.writeStructBegin('UpdateUser');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.DeleteUser = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.DeleteUser.prototype = {};
User.DeleteUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.DeleteUser.prototype.write = function(output) {
  output.writeStructBegin('DeleteUser');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetUserReq = function(args) {
  this.pageSize = null;
  this.pageState = null;
  if (args) {
    if (args.pageSize !== undefined && args.pageSize !== null) {
      this.pageSize = args.pageSize;
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
User.GetUserReq.prototype = {};
User.GetUserReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 2:
      if (ftype == Thrift.Type.I16) {
        this.pageSize = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetUserReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserReq');
  if (this.pageSize !== null && this.pageSize !== undefined) {
    output.writeFieldBegin('pageSize', Thrift.Type.I16, 2);
    output.writeI16(this.pageSize);
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 3);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetUserResp = function(args) {
  this.users = null;
  this.pageState = null;
  if (args) {
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [User.User]);
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
User.GetUserResp.prototype = {};
User.GetUserResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.users = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = new User.User();
          elem38.read(input);
          this.users.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetUserResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserResp');
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter39 in this.users)
    {
      if (this.users.hasOwnProperty(iter39))
      {
        iter39 = this.users[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 2);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetUserByUserIdReq = function(args) {
  this.userIds = null;
  if (args) {
    if (args.userIds !== undefined && args.userIds !== null) {
      this.userIds = Thrift.copyList(args.userIds, [null]);
    }
  }
};
User.GetUserByUserIdReq.prototype = {};
User.GetUserByUserIdReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.userIds = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = input.readString().value;
          this.userIds.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetUserByUserIdReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserByUserIdReq');
  if (this.userIds !== null && this.userIds !== undefined) {
    output.writeFieldBegin('userIds', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.userIds.length);
    for (var iter47 in this.userIds)
    {
      if (this.userIds.hasOwnProperty(iter47))
      {
        iter47 = this.userIds[iter47];
        output.writeString(iter47);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetUserByUserIdResp = function(args) {
  this.users = null;
  if (args) {
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [User.User]);
    }
  }
};
User.GetUserByUserIdResp.prototype = {};
User.GetUserByUserIdResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.users = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new User.User();
          elem54.read(input);
          this.users.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetUserByUserIdResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserByUserIdResp');
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter55 in this.users)
    {
      if (this.users.hasOwnProperty(iter55))
      {
        iter55 = this.users[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.UpdatePasswd = function(args) {
  this.email = null;
  this.oldPasswd = null;
  this.newPasswd = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.oldPasswd !== undefined && args.oldPasswd !== null) {
      this.oldPasswd = args.oldPasswd;
    }
    if (args.newPasswd !== undefined && args.newPasswd !== null) {
      this.newPasswd = args.newPasswd;
    }
  }
};
User.UpdatePasswd.prototype = {};
User.UpdatePasswd.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.oldPasswd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.newPasswd = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.UpdatePasswd.prototype.write = function(output) {
  output.writeStructBegin('UpdatePasswd');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.oldPasswd !== null && this.oldPasswd !== undefined) {
    output.writeFieldBegin('oldPasswd', Thrift.Type.STRING, 2);
    output.writeString(this.oldPasswd);
    output.writeFieldEnd();
  }
  if (this.newPasswd !== null && this.newPasswd !== undefined) {
    output.writeFieldBegin('newPasswd', Thrift.Type.STRING, 3);
    output.writeString(this.newPasswd);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetCaptchaReq = function(args) {
};
User.GetCaptchaReq.prototype = {};
User.GetCaptchaReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetCaptchaReq.prototype.write = function(output) {
  output.writeStructBegin('GetCaptchaReq');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetCaptchaResp = function(args) {
  this.captchaId = null;
  this.captchaSvg = null;
  if (args) {
    if (args.captchaId !== undefined && args.captchaId !== null) {
      this.captchaId = args.captchaId;
    }
    if (args.captchaSvg !== undefined && args.captchaSvg !== null) {
      this.captchaSvg = args.captchaSvg;
    }
  }
};
User.GetCaptchaResp.prototype = {};
User.GetCaptchaResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.captchaId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.captchaSvg = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetCaptchaResp.prototype.write = function(output) {
  output.writeStructBegin('GetCaptchaResp');
  if (this.captchaId !== null && this.captchaId !== undefined) {
    output.writeFieldBegin('captchaId', Thrift.Type.STRING, 1);
    output.writeString(this.captchaId);
    output.writeFieldEnd();
  }
  if (this.captchaSvg !== null && this.captchaSvg !== undefined) {
    output.writeFieldBegin('captchaSvg', Thrift.Type.STRING, 2);
    output.writeString(this.captchaSvg);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.SignupReq = function(args) {
  this.email = null;
  this.userId = null;
  this.firstName = null;
  this.lastName = null;
  this.password = null;
  this.profile = null;
  this.captchaText = null;
  this.captchaId = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
    if (args.profile !== undefined && args.profile !== null) {
      this.profile = args.profile;
    }
    if (args.captchaText !== undefined && args.captchaText !== null) {
      this.captchaText = args.captchaText;
    }
    if (args.captchaId !== undefined && args.captchaId !== null) {
      this.captchaId = args.captchaId;
    }
  }
};
User.SignupReq.prototype = {};
User.SignupReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.profile = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.captchaText = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.captchaId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.SignupReq.prototype.write = function(output) {
  output.writeStructBegin('SignupReq');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 2);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 3);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 4);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 5);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  if (this.profile !== null && this.profile !== undefined) {
    output.writeFieldBegin('profile', Thrift.Type.I32, 6);
    output.writeI32(this.profile);
    output.writeFieldEnd();
  }
  if (this.captchaText !== null && this.captchaText !== undefined) {
    output.writeFieldBegin('captchaText', Thrift.Type.STRING, 7);
    output.writeString(this.captchaText);
    output.writeFieldEnd();
  }
  if (this.captchaId !== null && this.captchaId !== undefined) {
    output.writeFieldBegin('captchaId', Thrift.Type.STRING, 8);
    output.writeString(this.captchaId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.SignupResp = function(args) {
  this.user = null;
  this.error = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new User.User(args.user);
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = args.error;
    }
  }
};
User.SignupResp.prototype = {};
User.SignupResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new User.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.error = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.SignupResp.prototype.write = function(output) {
  output.writeStructBegin('SignupResp');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRING, 2);
    output.writeString(this.error);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Location = function(args) {
  this.lat = null;
  this.lon = null;
  if (args) {
    if (args.lat !== undefined && args.lat !== null) {
      this.lat = args.lat;
    }
    if (args.lon !== undefined && args.lon !== null) {
      this.lon = args.lon;
    }
  }
};
User.Location.prototype = {};
User.Location.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.DOUBLE) {
        this.lat = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.DOUBLE) {
        this.lon = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Location.prototype.write = function(output) {
  output.writeStructBegin('Location');
  if (this.lat !== null && this.lat !== undefined) {
    output.writeFieldBegin('lat', Thrift.Type.DOUBLE, 1);
    output.writeDouble(this.lat);
    output.writeFieldEnd();
  }
  if (this.lon !== null && this.lon !== undefined) {
    output.writeFieldBegin('lon', Thrift.Type.DOUBLE, 2);
    output.writeDouble(this.lon);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Address = function(args) {
  this.country = null;
  this.state = null;
  this.city = null;
  this.street = null;
  if (args) {
    if (args.country !== undefined && args.country !== null) {
      this.country = args.country;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.city !== undefined && args.city !== null) {
      this.city = args.city;
    }
    if (args.street !== undefined && args.street !== null) {
      this.street = args.street;
    }
  }
};
User.Address.prototype = {};
User.Address.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.country = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.state = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.city = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.street = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Address.prototype.write = function(output) {
  output.writeStructBegin('Address');
  if (this.country !== null && this.country !== undefined) {
    output.writeFieldBegin('country', Thrift.Type.STRING, 1);
    output.writeString(this.country);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.STRING, 2);
    output.writeString(this.state);
    output.writeFieldEnd();
  }
  if (this.city !== null && this.city !== undefined) {
    output.writeFieldBegin('city', Thrift.Type.STRING, 3);
    output.writeString(this.city);
    output.writeFieldEnd();
  }
  if (this.street !== null && this.street !== undefined) {
    output.writeFieldBegin('street', Thrift.Type.STRING, 4);
    output.writeString(this.street);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.SendEmailReq = function(args) {
  this.sendTo = null;
  this.sendFrom = null;
  this.replyTo = null;
  this.subject = null;
  this.htmlBody = null;
  if (args) {
    if (args.sendTo !== undefined && args.sendTo !== null) {
      this.sendTo = args.sendTo;
    }
    if (args.sendFrom !== undefined && args.sendFrom !== null) {
      this.sendFrom = args.sendFrom;
    }
    if (args.replyTo !== undefined && args.replyTo !== null) {
      this.replyTo = args.replyTo;
    }
    if (args.subject !== undefined && args.subject !== null) {
      this.subject = args.subject;
    }
    if (args.htmlBody !== undefined && args.htmlBody !== null) {
      this.htmlBody = args.htmlBody;
    }
  }
};
User.SendEmailReq.prototype = {};
User.SendEmailReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.sendTo = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.sendFrom = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.replyTo = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.subject = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.htmlBody = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.SendEmailReq.prototype.write = function(output) {
  output.writeStructBegin('SendEmailReq');
  if (this.sendTo !== null && this.sendTo !== undefined) {
    output.writeFieldBegin('sendTo', Thrift.Type.STRING, 1);
    output.writeString(this.sendTo);
    output.writeFieldEnd();
  }
  if (this.sendFrom !== null && this.sendFrom !== undefined) {
    output.writeFieldBegin('sendFrom', Thrift.Type.STRING, 2);
    output.writeString(this.sendFrom);
    output.writeFieldEnd();
  }
  if (this.replyTo !== null && this.replyTo !== undefined) {
    output.writeFieldBegin('replyTo', Thrift.Type.STRING, 3);
    output.writeString(this.replyTo);
    output.writeFieldEnd();
  }
  if (this.subject !== null && this.subject !== undefined) {
    output.writeFieldBegin('subject', Thrift.Type.STRING, 4);
    output.writeString(this.subject);
    output.writeFieldEnd();
  }
  if (this.htmlBody !== null && this.htmlBody !== undefined) {
    output.writeFieldBegin('htmlBody', Thrift.Type.STRING, 5);
    output.writeString(this.htmlBody);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.SendEmailResp = function(args) {
  this.info = null;
  this.error = null;
  if (args) {
    if (args.info !== undefined && args.info !== null) {
      this.info = args.info;
    }
    if (args.error !== undefined && args.error !== null) {
      this.error = args.error;
    }
  }
};
User.SendEmailResp.prototype = {};
User.SendEmailResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.info = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.error = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.SendEmailResp.prototype.write = function(output) {
  output.writeStructBegin('SendEmailResp');
  if (this.info !== null && this.info !== undefined) {
    output.writeFieldBegin('info', Thrift.Type.STRING, 1);
    output.writeString(this.info);
    output.writeFieldEnd();
  }
  if (this.error !== null && this.error !== undefined) {
    output.writeFieldBegin('error', Thrift.Type.STRING, 2);
    output.writeString(this.error);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Education = function(args) {
  this.id = null;
  this.school = null;
  this.schoolId = null;
  this.degree = null;
  this.fos = null;
  this.description = null;
  this.yearFrom = null;
  this.yearTo = null;
  this.urls = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.school !== undefined && args.school !== null) {
      this.school = args.school;
    }
    if (args.schoolId !== undefined && args.schoolId !== null) {
      this.schoolId = args.schoolId;
    }
    if (args.degree !== undefined && args.degree !== null) {
      this.degree = args.degree;
    }
    if (args.fos !== undefined && args.fos !== null) {
      this.fos = args.fos;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.yearFrom !== undefined && args.yearFrom !== null) {
      this.yearFrom = args.yearFrom;
    }
    if (args.yearTo !== undefined && args.yearTo !== null) {
      this.yearTo = args.yearTo;
    }
    if (args.urls !== undefined && args.urls !== null) {
      this.urls = Thrift.copyList(args.urls, [null]);
    }
  }
};
User.Education.prototype = {};
User.Education.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.school = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.schoolId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.degree = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.fos = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I16) {
        this.yearFrom = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I16) {
        this.yearTo = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size56 = 0;
        var _rtmp360;
        this.urls = [];
        var _etype59 = 0;
        _rtmp360 = input.readListBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = input.readString().value;
          this.urls.push(elem62);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Education.prototype.write = function(output) {
  output.writeStructBegin('Education');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.school !== null && this.school !== undefined) {
    output.writeFieldBegin('school', Thrift.Type.STRING, 2);
    output.writeString(this.school);
    output.writeFieldEnd();
  }
  if (this.schoolId !== null && this.schoolId !== undefined) {
    output.writeFieldBegin('schoolId', Thrift.Type.STRING, 3);
    output.writeString(this.schoolId);
    output.writeFieldEnd();
  }
  if (this.degree !== null && this.degree !== undefined) {
    output.writeFieldBegin('degree', Thrift.Type.STRING, 4);
    output.writeString(this.degree);
    output.writeFieldEnd();
  }
  if (this.fos !== null && this.fos !== undefined) {
    output.writeFieldBegin('fos', Thrift.Type.STRING, 5);
    output.writeString(this.fos);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 6);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.yearFrom !== null && this.yearFrom !== undefined) {
    output.writeFieldBegin('yearFrom', Thrift.Type.I16, 7);
    output.writeI16(this.yearFrom);
    output.writeFieldEnd();
  }
  if (this.yearTo !== null && this.yearTo !== undefined) {
    output.writeFieldBegin('yearTo', Thrift.Type.I16, 8);
    output.writeI16(this.yearTo);
    output.writeFieldEnd();
  }
  if (this.urls !== null && this.urls !== undefined) {
    output.writeFieldBegin('urls', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRING, this.urls.length);
    for (var iter63 in this.urls)
    {
      if (this.urls.hasOwnProperty(iter63))
      {
        iter63 = this.urls[iter63];
        output.writeString(iter63);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.UpdateEducation = function(args) {
  this.userId = null;
  this.education = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.education !== undefined && args.education !== null) {
      this.education = new User.Education(args.education);
    }
  }
};
User.UpdateEducation.prototype = {};
User.UpdateEducation.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.education = new User.Education();
        this.education.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.UpdateEducation.prototype.write = function(output) {
  output.writeStructBegin('UpdateEducation');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.education !== null && this.education !== undefined) {
    output.writeFieldBegin('education', Thrift.Type.STRUCT, 2);
    this.education.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.DeleteEducation = function(args) {
  this.userId = null;
  this.id = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
User.DeleteEducation.prototype = {};
User.DeleteEducation.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.DeleteEducation.prototype.write = function(output) {
  output.writeStructBegin('DeleteEducation');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetEducationReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.GetEducationReq.prototype = {};
User.GetEducationReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetEducationReq.prototype.write = function(output) {
  output.writeStructBegin('GetEducationReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetEducationResp = function(args) {
  this.userId = null;
  this.education = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.education !== undefined && args.education !== null) {
      this.education = Thrift.copyList(args.education, [User.Education]);
    }
  }
};
User.GetEducationResp.prototype = {};
User.GetEducationResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size64 = 0;
        var _rtmp368;
        this.education = [];
        var _etype67 = 0;
        _rtmp368 = input.readListBegin();
        _etype67 = _rtmp368.etype;
        _size64 = _rtmp368.size;
        for (var _i69 = 0; _i69 < _size64; ++_i69)
        {
          var elem70 = null;
          elem70 = new User.Education();
          elem70.read(input);
          this.education.push(elem70);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetEducationResp.prototype.write = function(output) {
  output.writeStructBegin('GetEducationResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.education !== null && this.education !== undefined) {
    output.writeFieldBegin('education', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.education.length);
    for (var iter71 in this.education)
    {
      if (this.education.hasOwnProperty(iter71))
      {
        iter71 = this.education[iter71];
        iter71.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Experience = function(args) {
  this.id = null;
  this.title = null;
  this.company = null;
  this.companyId = null;
  this.location = null;
  this.description = null;
  this.yearFrom = null;
  this.monthFrom = null;
  this.yearTo = null;
  this.monthTo = null;
  this.urls = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.company !== undefined && args.company !== null) {
      this.company = args.company;
    }
    if (args.companyId !== undefined && args.companyId !== null) {
      this.companyId = args.companyId;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.yearFrom !== undefined && args.yearFrom !== null) {
      this.yearFrom = args.yearFrom;
    }
    if (args.monthFrom !== undefined && args.monthFrom !== null) {
      this.monthFrom = args.monthFrom;
    }
    if (args.yearTo !== undefined && args.yearTo !== null) {
      this.yearTo = args.yearTo;
    }
    if (args.monthTo !== undefined && args.monthTo !== null) {
      this.monthTo = args.monthTo;
    }
    if (args.urls !== undefined && args.urls !== null) {
      this.urls = Thrift.copyList(args.urls, [null]);
    }
  }
};
User.Experience.prototype = {};
User.Experience.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.company = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.companyId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I16) {
        this.yearFrom = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I16) {
        this.monthFrom = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I16) {
        this.yearTo = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I16) {
        this.monthTo = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.LIST) {
        var _size72 = 0;
        var _rtmp376;
        this.urls = [];
        var _etype75 = 0;
        _rtmp376 = input.readListBegin();
        _etype75 = _rtmp376.etype;
        _size72 = _rtmp376.size;
        for (var _i77 = 0; _i77 < _size72; ++_i77)
        {
          var elem78 = null;
          elem78 = input.readString().value;
          this.urls.push(elem78);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Experience.prototype.write = function(output) {
  output.writeStructBegin('Experience');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 2);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.company !== null && this.company !== undefined) {
    output.writeFieldBegin('company', Thrift.Type.STRING, 3);
    output.writeString(this.company);
    output.writeFieldEnd();
  }
  if (this.companyId !== null && this.companyId !== undefined) {
    output.writeFieldBegin('companyId', Thrift.Type.STRING, 4);
    output.writeString(this.companyId);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 5);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 6);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.yearFrom !== null && this.yearFrom !== undefined) {
    output.writeFieldBegin('yearFrom', Thrift.Type.I16, 7);
    output.writeI16(this.yearFrom);
    output.writeFieldEnd();
  }
  if (this.monthFrom !== null && this.monthFrom !== undefined) {
    output.writeFieldBegin('monthFrom', Thrift.Type.I16, 8);
    output.writeI16(this.monthFrom);
    output.writeFieldEnd();
  }
  if (this.yearTo !== null && this.yearTo !== undefined) {
    output.writeFieldBegin('yearTo', Thrift.Type.I16, 9);
    output.writeI16(this.yearTo);
    output.writeFieldEnd();
  }
  if (this.monthTo !== null && this.monthTo !== undefined) {
    output.writeFieldBegin('monthTo', Thrift.Type.I16, 10);
    output.writeI16(this.monthTo);
    output.writeFieldEnd();
  }
  if (this.urls !== null && this.urls !== undefined) {
    output.writeFieldBegin('urls', Thrift.Type.LIST, 11);
    output.writeListBegin(Thrift.Type.STRING, this.urls.length);
    for (var iter79 in this.urls)
    {
      if (this.urls.hasOwnProperty(iter79))
      {
        iter79 = this.urls[iter79];
        output.writeString(iter79);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.UpdateExperience = function(args) {
  this.userId = null;
  this.experience = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.experience !== undefined && args.experience !== null) {
      this.experience = new User.Experience(args.experience);
    }
  }
};
User.UpdateExperience.prototype = {};
User.UpdateExperience.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.experience = new User.Experience();
        this.experience.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.UpdateExperience.prototype.write = function(output) {
  output.writeStructBegin('UpdateExperience');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.experience !== null && this.experience !== undefined) {
    output.writeFieldBegin('experience', Thrift.Type.STRUCT, 2);
    this.experience.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.DeleteExperience = function(args) {
  this.userId = null;
  this.id = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
  }
};
User.DeleteExperience.prototype = {};
User.DeleteExperience.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.DeleteExperience.prototype.write = function(output) {
  output.writeStructBegin('DeleteExperience');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 2);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetExperienceReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.GetExperienceReq.prototype = {};
User.GetExperienceReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetExperienceReq.prototype.write = function(output) {
  output.writeStructBegin('GetExperienceReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetExperienceResp = function(args) {
  this.userId = null;
  this.experience = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.experience !== undefined && args.experience !== null) {
      this.experience = Thrift.copyList(args.experience, [User.Experience]);
    }
  }
};
User.GetExperienceResp.prototype = {};
User.GetExperienceResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size80 = 0;
        var _rtmp384;
        this.experience = [];
        var _etype83 = 0;
        _rtmp384 = input.readListBegin();
        _etype83 = _rtmp384.etype;
        _size80 = _rtmp384.size;
        for (var _i85 = 0; _i85 < _size80; ++_i85)
        {
          var elem86 = null;
          elem86 = new User.Experience();
          elem86.read(input);
          this.experience.push(elem86);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetExperienceResp.prototype.write = function(output) {
  output.writeStructBegin('GetExperienceResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.experience !== null && this.experience !== undefined) {
    output.writeFieldBegin('experience', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.experience.length);
    for (var iter87 in this.experience)
    {
      if (this.experience.hasOwnProperty(iter87))
      {
        iter87 = this.experience[iter87];
        iter87.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetUserFlowReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.GetUserFlowReq.prototype = {};
User.GetUserFlowReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetUserFlowReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserFlowReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetUserFlowResp = function(args) {
  this.userId = null;
  this.flow = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.flow !== undefined && args.flow !== null) {
      this.flow = args.flow;
    }
  }
};
User.GetUserFlowResp.prototype = {};
User.GetUserFlowResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.flow = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetUserFlowResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserFlowResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.flow !== null && this.flow !== undefined) {
    output.writeFieldBegin('flow', Thrift.Type.I32, 2);
    output.writeI32(this.flow);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.SetUserFlowReq = function(args) {
  this.userId = null;
  this.flow = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.flow !== undefined && args.flow !== null) {
      this.flow = args.flow;
    }
  }
};
User.SetUserFlowReq.prototype = {};
User.SetUserFlowReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.flow = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.SetUserFlowReq.prototype.write = function(output) {
  output.writeStructBegin('SetUserFlowReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.flow !== null && this.flow !== undefined) {
    output.writeFieldBegin('flow', Thrift.Type.I32, 2);
    output.writeI32(this.flow);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.SetUserFlowResp = function(args) {
  this.userId = null;
  this.flow = null;
  this.err = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.flow !== undefined && args.flow !== null) {
      this.flow = args.flow;
    }
    if (args.err !== undefined && args.err !== null) {
      this.err = args.err;
    }
  }
};
User.SetUserFlowResp.prototype = {};
User.SetUserFlowResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.flow = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.err = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.SetUserFlowResp.prototype.write = function(output) {
  output.writeStructBegin('SetUserFlowResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.flow !== null && this.flow !== undefined) {
    output.writeFieldBegin('flow', Thrift.Type.I32, 2);
    output.writeI32(this.flow);
    output.writeFieldEnd();
  }
  if (this.err !== null && this.err !== undefined) {
    output.writeFieldBegin('err', Thrift.Type.STRING, 3);
    output.writeString(this.err);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.BcUserRegister = function(args) {
  this.bcAddress = null;
  this.err = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
    if (args.err !== undefined && args.err !== null) {
      this.err = args.err;
    }
  }
};
User.BcUserRegister.prototype = {};
User.BcUserRegister.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.err = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.BcUserRegister.prototype.write = function(output) {
  output.writeStructBegin('BcUserRegister');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  if (this.err !== null && this.err !== undefined) {
    output.writeFieldBegin('err', Thrift.Type.STRING, 2);
    output.writeString(this.err);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.FollowUserReq = function(args) {
  this.userId = null;
  this.followUserId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.followUserId !== undefined && args.followUserId !== null) {
      this.followUserId = args.followUserId;
    }
  }
};
User.FollowUserReq.prototype = {};
User.FollowUserReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.followUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.FollowUserReq.prototype.write = function(output) {
  output.writeStructBegin('FollowUserReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.followUserId !== null && this.followUserId !== undefined) {
    output.writeFieldBegin('followUserId', Thrift.Type.STRING, 2);
    output.writeString(this.followUserId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.FollowUserResp = function(args) {
  this.userId = null;
  this.followUserId = null;
  this.followStatus = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.followUserId !== undefined && args.followUserId !== null) {
      this.followUserId = args.followUserId;
    }
    if (args.followStatus !== undefined && args.followStatus !== null) {
      this.followStatus = args.followStatus;
    }
  }
};
User.FollowUserResp.prototype = {};
User.FollowUserResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.followUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.followStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.FollowUserResp.prototype.write = function(output) {
  output.writeStructBegin('FollowUserResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.followUserId !== null && this.followUserId !== undefined) {
    output.writeFieldBegin('followUserId', Thrift.Type.STRING, 2);
    output.writeString(this.followUserId);
    output.writeFieldEnd();
  }
  if (this.followStatus !== null && this.followStatus !== undefined) {
    output.writeFieldBegin('followStatus', Thrift.Type.I32, 3);
    output.writeI32(this.followStatus);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.UnfollowUserReq = function(args) {
  this.userId = null;
  this.followUserId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.followUserId !== undefined && args.followUserId !== null) {
      this.followUserId = args.followUserId;
    }
  }
};
User.UnfollowUserReq.prototype = {};
User.UnfollowUserReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.followUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.UnfollowUserReq.prototype.write = function(output) {
  output.writeStructBegin('UnfollowUserReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.followUserId !== null && this.followUserId !== undefined) {
    output.writeFieldBegin('followUserId', Thrift.Type.STRING, 2);
    output.writeString(this.followUserId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.UnfollowUserResp = function(args) {
  this.userId = null;
  this.followUserId = null;
  this.followStatus = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.followUserId !== undefined && args.followUserId !== null) {
      this.followUserId = args.followUserId;
    }
    if (args.followStatus !== undefined && args.followStatus !== null) {
      this.followStatus = args.followStatus;
    }
  }
};
User.UnfollowUserResp.prototype = {};
User.UnfollowUserResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.followUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.followStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.UnfollowUserResp.prototype.write = function(output) {
  output.writeStructBegin('UnfollowUserResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.followUserId !== null && this.followUserId !== undefined) {
    output.writeFieldBegin('followUserId', Thrift.Type.STRING, 2);
    output.writeString(this.followUserId);
    output.writeFieldEnd();
  }
  if (this.followStatus !== null && this.followStatus !== undefined) {
    output.writeFieldBegin('followStatus', Thrift.Type.I32, 3);
    output.writeI32(this.followStatus);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetFollowingReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.GetFollowingReq.prototype = {};
User.GetFollowingReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetFollowingReq.prototype.write = function(output) {
  output.writeStructBegin('GetFollowingReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetFollowingResp = function(args) {
  this.userId = null;
  this.followingIds = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.followingIds !== undefined && args.followingIds !== null) {
      this.followingIds = Thrift.copyList(args.followingIds, [null]);
    }
  }
};
User.GetFollowingResp.prototype = {};
User.GetFollowingResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size88 = 0;
        var _rtmp392;
        this.followingIds = [];
        var _etype91 = 0;
        _rtmp392 = input.readListBegin();
        _etype91 = _rtmp392.etype;
        _size88 = _rtmp392.size;
        for (var _i93 = 0; _i93 < _size88; ++_i93)
        {
          var elem94 = null;
          elem94 = input.readString().value;
          this.followingIds.push(elem94);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetFollowingResp.prototype.write = function(output) {
  output.writeStructBegin('GetFollowingResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.followingIds !== null && this.followingIds !== undefined) {
    output.writeFieldBegin('followingIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.followingIds.length);
    for (var iter95 in this.followingIds)
    {
      if (this.followingIds.hasOwnProperty(iter95))
      {
        iter95 = this.followingIds[iter95];
        output.writeString(iter95);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetFollowersReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
User.GetFollowersReq.prototype = {};
User.GetFollowersReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetFollowersReq.prototype.write = function(output) {
  output.writeStructBegin('GetFollowersReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetFollowersResp = function(args) {
  this.userId = null;
  this.followersIds = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.followersIds !== undefined && args.followersIds !== null) {
      this.followersIds = Thrift.copyList(args.followersIds, [null]);
    }
  }
};
User.GetFollowersResp.prototype = {};
User.GetFollowersResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size96 = 0;
        var _rtmp3100;
        this.followersIds = [];
        var _etype99 = 0;
        _rtmp3100 = input.readListBegin();
        _etype99 = _rtmp3100.etype;
        _size96 = _rtmp3100.size;
        for (var _i101 = 0; _i101 < _size96; ++_i101)
        {
          var elem102 = null;
          elem102 = input.readString().value;
          this.followersIds.push(elem102);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetFollowersResp.prototype.write = function(output) {
  output.writeStructBegin('GetFollowersResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.followersIds !== null && this.followersIds !== undefined) {
    output.writeFieldBegin('followersIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.followersIds.length);
    for (var iter103 in this.followersIds)
    {
      if (this.followersIds.hasOwnProperty(iter103))
      {
        iter103 = this.followersIds[iter103];
        output.writeString(iter103);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetFollowingTimelineReq = function(args) {
  this.userId = null;
  this.date = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.date !== undefined && args.date !== null) {
      this.date = args.date;
    }
  }
};
User.GetFollowingTimelineReq.prototype = {};
User.GetFollowingTimelineReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.date = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetFollowingTimelineReq.prototype.write = function(output) {
  output.writeStructBegin('GetFollowingTimelineReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.date !== null && this.date !== undefined) {
    output.writeFieldBegin('date', Thrift.Type.STRING, 2);
    output.writeString(this.date);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.TimelineEntity = function(args) {
  this.userId = null;
  this.createdDate = null;
  this.createdTime = null;
  this.actionType = null;
  this.actionDesc = null;
  this.entityId = null;
  this.entityType = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.createdDate !== undefined && args.createdDate !== null) {
      this.createdDate = args.createdDate;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.actionType !== undefined && args.actionType !== null) {
      this.actionType = args.actionType;
    }
    if (args.actionDesc !== undefined && args.actionDesc !== null) {
      this.actionDesc = args.actionDesc;
    }
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.entityType !== undefined && args.entityType !== null) {
      this.entityType = args.entityType;
    }
  }
};
User.TimelineEntity.prototype = {};
User.TimelineEntity.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.createdDate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.actionType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.actionDesc = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.entityType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.TimelineEntity.prototype.write = function(output) {
  output.writeStructBegin('TimelineEntity');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.createdDate !== null && this.createdDate !== undefined) {
    output.writeFieldBegin('createdDate', Thrift.Type.STRING, 2);
    output.writeString(this.createdDate);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 3);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.actionType !== null && this.actionType !== undefined) {
    output.writeFieldBegin('actionType', Thrift.Type.I32, 4);
    output.writeI32(this.actionType);
    output.writeFieldEnd();
  }
  if (this.actionDesc !== null && this.actionDesc !== undefined) {
    output.writeFieldBegin('actionDesc', Thrift.Type.STRING, 5);
    output.writeString(this.actionDesc);
    output.writeFieldEnd();
  }
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 6);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.entityType !== null && this.entityType !== undefined) {
    output.writeFieldBegin('entityType', Thrift.Type.I32, 7);
    output.writeI32(this.entityType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetFollowingTimelineResp = function(args) {
  this.userId = null;
  this.entities = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.entities !== undefined && args.entities !== null) {
      this.entities = Thrift.copyList(args.entities, [User.TimelineEntity]);
    }
  }
};
User.GetFollowingTimelineResp.prototype = {};
User.GetFollowingTimelineResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size104 = 0;
        var _rtmp3108;
        this.entities = [];
        var _etype107 = 0;
        _rtmp3108 = input.readListBegin();
        _etype107 = _rtmp3108.etype;
        _size104 = _rtmp3108.size;
        for (var _i109 = 0; _i109 < _size104; ++_i109)
        {
          var elem110 = null;
          elem110 = new User.TimelineEntity();
          elem110.read(input);
          this.entities.push(elem110);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetFollowingTimelineResp.prototype.write = function(output) {
  output.writeStructBegin('GetFollowingTimelineResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.entities !== null && this.entities !== undefined) {
    output.writeFieldBegin('entities', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.entities.length);
    for (var iter111 in this.entities)
    {
      if (this.entities.hasOwnProperty(iter111))
      {
        iter111 = this.entities[iter111];
        iter111.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.Notification = function(args) {
  this.userId = null;
  this.notifType = null;
  this.entityId = null;
  this.entityType = null;
  this.createdTime = null;
  this.experationTime = null;
  this.ackTime = null;
  this.notifDesc = null;
  this.triggeredByUserId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.notifType !== undefined && args.notifType !== null) {
      this.notifType = args.notifType;
    }
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.entityType !== undefined && args.entityType !== null) {
      this.entityType = args.entityType;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.experationTime !== undefined && args.experationTime !== null) {
      this.experationTime = args.experationTime;
    }
    if (args.ackTime !== undefined && args.ackTime !== null) {
      this.ackTime = args.ackTime;
    }
    if (args.notifDesc !== undefined && args.notifDesc !== null) {
      this.notifDesc = args.notifDesc;
    }
    if (args.triggeredByUserId !== undefined && args.triggeredByUserId !== null) {
      this.triggeredByUserId = args.triggeredByUserId;
    }
  }
};
User.Notification.prototype = {};
User.Notification.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.notifType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.entityType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.experationTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.ackTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.notifDesc = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.triggeredByUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.Notification.prototype.write = function(output) {
  output.writeStructBegin('Notification');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.notifType !== null && this.notifType !== undefined) {
    output.writeFieldBegin('notifType', Thrift.Type.I32, 2);
    output.writeI32(this.notifType);
    output.writeFieldEnd();
  }
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 3);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.entityType !== null && this.entityType !== undefined) {
    output.writeFieldBegin('entityType', Thrift.Type.I32, 4);
    output.writeI32(this.entityType);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 5);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.experationTime !== null && this.experationTime !== undefined) {
    output.writeFieldBegin('experationTime', Thrift.Type.I64, 6);
    output.writeI64(this.experationTime);
    output.writeFieldEnd();
  }
  if (this.ackTime !== null && this.ackTime !== undefined) {
    output.writeFieldBegin('ackTime', Thrift.Type.I64, 7);
    output.writeI64(this.ackTime);
    output.writeFieldEnd();
  }
  if (this.notifDesc !== null && this.notifDesc !== undefined) {
    output.writeFieldBegin('notifDesc', Thrift.Type.STRING, 8);
    output.writeString(this.notifDesc);
    output.writeFieldEnd();
  }
  if (this.triggeredByUserId !== null && this.triggeredByUserId !== undefined) {
    output.writeFieldBegin('triggeredByUserId', Thrift.Type.STRING, 9);
    output.writeString(this.triggeredByUserId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetNotificationReq = function(args) {
  this.userId = null;
  this.history = null;
  this.fromTime = null;
  this.pageSize = null;
  this.pageState = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.history !== undefined && args.history !== null) {
      this.history = args.history;
    }
    if (args.fromTime !== undefined && args.fromTime !== null) {
      this.fromTime = args.fromTime;
    }
    if (args.pageSize !== undefined && args.pageSize !== null) {
      this.pageSize = args.pageSize;
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
User.GetNotificationReq.prototype = {};
User.GetNotificationReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.history = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.fromTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I16) {
        this.pageSize = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetNotificationReq.prototype.write = function(output) {
  output.writeStructBegin('GetNotificationReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.history !== null && this.history !== undefined) {
    output.writeFieldBegin('history', Thrift.Type.BOOL, 2);
    output.writeBool(this.history);
    output.writeFieldEnd();
  }
  if (this.fromTime !== null && this.fromTime !== undefined) {
    output.writeFieldBegin('fromTime', Thrift.Type.I64, 3);
    output.writeI64(this.fromTime);
    output.writeFieldEnd();
  }
  if (this.pageSize !== null && this.pageSize !== undefined) {
    output.writeFieldBegin('pageSize', Thrift.Type.I16, 4);
    output.writeI16(this.pageSize);
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 5);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.GetNotificationResp = function(args) {
  this.userId = null;
  this.notifications = null;
  this.pageState = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.notifications !== undefined && args.notifications !== null) {
      this.notifications = Thrift.copyList(args.notifications, [User.Notification]);
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
User.GetNotificationResp.prototype = {};
User.GetNotificationResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size112 = 0;
        var _rtmp3116;
        this.notifications = [];
        var _etype115 = 0;
        _rtmp3116 = input.readListBegin();
        _etype115 = _rtmp3116.etype;
        _size112 = _rtmp3116.size;
        for (var _i117 = 0; _i117 < _size112; ++_i117)
        {
          var elem118 = null;
          elem118 = new User.Notification();
          elem118.read(input);
          this.notifications.push(elem118);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.GetNotificationResp.prototype.write = function(output) {
  output.writeStructBegin('GetNotificationResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.notifications !== null && this.notifications !== undefined) {
    output.writeFieldBegin('notifications', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.notifications.length);
    for (var iter119 in this.notifications)
    {
      if (this.notifications.hasOwnProperty(iter119))
      {
        iter119 = this.notifications[iter119];
        iter119.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 3);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

User.AckNotification = function(args) {
  this.userId = null;
  this.createdTime = null;
  this.notifType = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.notifType !== undefined && args.notifType !== null) {
      this.notifType = args.notifType;
    }
  }
};
User.AckNotification.prototype = {};
User.AckNotification.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.notifType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.AckNotification.prototype.write = function(output) {
  output.writeStructBegin('AckNotification');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 2);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.notifType !== null && this.notifType !== undefined) {
    output.writeFieldBegin('notifType', Thrift.Type.I32, 3);
    output.writeI32(this.notifType);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

