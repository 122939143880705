//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


if (typeof Msg === 'undefined') {
  Msg = {};
}
Msg.MsgType = {
  'ERROR' : -1,
  'EMPTY' : 0,
  'LOGIN_REQ' : 1,
  'LOGIN_RESP' : 2,
  'LOGOUT' : 3,
  'GET_SESSION_REQ' : 4,
  'GET_SESSION_RESP' : 5,
  'UPDATE_USER' : 100,
  'DELETE_USER' : 101,
  'UPDATE_PASSWD' : 102,
  'GET_USER_REQ' : 103,
  'GET_USER_RESP' : 104,
  'GET_CAPTCHA_REQ' : 105,
  'GET_CAPTCHA_RESP' : 106,
  'SIGNUP_REQ' : 107,
  'SIGNUP_RESP' : 108,
  'SEND_EMAIL_REQ' : 109,
  'SEND_EMAIL_RESP' : 110,
  'UPDATE_EDUCATION' : 111,
  'DELETE_EDUCATION' : 112,
  'GET_EDUCATION_REQ' : 113,
  'GET_EDUCATION_RESP' : 114,
  'UPDATE_EXPERIENCE' : 115,
  'DELETE_EXPERIENCE' : 116,
  'GET_EXPERIENCE_REQ' : 117,
  'GET_EXPERIENCE_RESP' : 118,
  'GET_USER_FLOW_REQ' : 119,
  'GET_USER_FLOW_RESP' : 120,
  'SET_USER_FLOW_REQ' : 121,
  'SET_USER_FLOW_RESP' : 122,
  'GENERATE_ACCOUNT_MERGE_HASH_REQ' : 123,
  'GENERATE_ACCOUNT_MERGE_HASH_RESP' : 124,
  'FOLLOW_USER_REQ' : 125,
  'FOLLOW_USER_RESP' : 126,
  'UNFOLLOW_USER_REQ' : 127,
  'UNFOLLOW_USER_RESP' : 128,
  'GET_FOLLOWING_REQ' : 129,
  'GET_FOLLOWING_RESP' : 130,
  'GET_FOLLOWERS_REQ' : 131,
  'GET_FOLLOWERS_RESP' : 132,
  'GET_FOLLOWING_TIMELINE_REQ' : 133,
  'GET_FOLLOWING_TIMELINE_RESP' : 134,
  'GET_USER_BY_USER_ID_REQ' : 135,
  'GET_USER_BY_USER_ID_RESP' : 136,
  'GET_NOTIFICATION_REQ' : 137,
  'GET_NOTIFICATION_RESP' : 138,
  'ACK_NOTIFICATION' : 139,
  'BC_USER_REGISTER' : 200,
  'SEARCH_ES_REQ' : 300,
  'SEARCH_ES_RESP' : 301,
  'GET_ES_REQ' : 302,
  'GET_ES_RESP' : 303,
  'SEARCH_VOCABULARY_REQ' : 304,
  'SEARCH_VOCABULARY_RESP' : 305,
  'PROCESS_WEBSHOT' : 500,
  'GET_WEBSHOT_REQ' : 501,
  'GET_WEBSHOT_RESP' : 502,
  'REINIT_TWITTER_CHANNELS' : 600,
  'CLOSE_TWITTER_CHANNELS' : 601,
  'GET_USER_BALANCE_REQ' : 800,
  'GET_USER_BALANCE_RESP' : 801,
  'IS_USER_REGISTERED_REQ' : 802,
  'IS_USER_REGISTERED_RESP' : 803,
  'GET_USER_DATA_REQ' : 804,
  'GET_USER_DATA_RESP' : 805,
  'SEND_ETHER_TO_USER_REQ' : 806,
  'SEND_ETHER_TO_USER_RESP' : 807,
  'TEST_WEB3' : 880,
  'INITIATE_CLAIM_ARTICLE' : 1000,
  'UPDATE_CLAIM_ARTICLE' : 1001,
  'GET_MY_PUBLISH_REQ' : 1002,
  'GET_MY_PUBLISH_RESP' : 1003,
  'BC_PUBLISH_ENTITY_UPDATE' : 1004,
  'GET_REVIEW_REQ' : 1005,
  'GET_REVIEW_RESP' : 1006,
  'UPDATE_REVIEW_REQ' : 1007,
  'UPDATE_REVIEW_RESP' : 1008,
  'GET_PUBLISH_REVIEW_REQ' : 1009,
  'GET_PUBLISH_REVIEW_RESP' : 1010,
  'GET_ARTICLE_REQ' : 1011,
  'GET_ARTICLE_RESP' : 1012,
  'UPDATE_ARTICLE_REQ' : 1013,
  'UPDATE_ARTICLE_RESP' : 1014,
  'GET_PUBLISH_ARTICLE_REQ' : 1015,
  'GET_PUBLISH_ARTICLE_RESP' : 1016,
  'DELETE_ARTICLE_REQ' : 1017,
  'DELETE_ARTICLE_RESP' : 1018,
  'PUBLISH_DUMMY' : 1019,
  'SET_SOCIAL_OPERATION_REQ' : 1020,
  'SET_SOCIAL_OPERATION_RESP' : 1021,
  'GET_USER_IDS_FOR_SOCIAL_OPERATION_REQ' : 1022,
  'GET_USER_IDS_FOR_SOCIAL_OPERATION_RESP' : 1023,
  'GET_SOCIAL_OPERATION_FOR_USER_ID_REQ' : 1024,
  'GET_SOCIAL_OPERATION_FOR_USER_ID_RESP' : 1025,
  'UPDATE_COMMENT_REQ' : 1026,
  'UPDATE_COMMENT_RESP' : 1027,
  'GET_COMMENT_REQ' : 1028,
  'GET_COMMENT_RESP' : 1029,
  'GET_COMMENT_FOR_ENTITY_REQ' : 1030,
  'GET_COMMENT_FOR_ENTITY_RESP' : 1031,
  'IS_BC_USER_REGISTERED_REQ' : 2000,
  'IS_BC_USER_REGISTERED_RESP' : 2001,
  'REGISTER_BC_USER_REQ' : 2002,
  'BC_OPERATION_RESP' : 2003,
  'CREATE_SCHEDULER_REQ' : 3000,
  'CREATE_SCHEDULER_RESP' : 3001,
  'UPDATE_SCHEDULER_REQ' : 3002,
  'UPDATE_SCHEDULER_RESP' : 3003,
  'DELETE_SCHEDULER_REQ' : 3004,
  'DELETE_SCHEDULER_RESP' : 3005,
  'GET_SCHEDULERS_REQ' : 3006,
  'GET_SCHEDULERS_RESP' : 3007
};
Msg.ErrorCode = {
  'GENERIC' : 1,
  'UNKNOWN_MSG_TYPE' : 2,
  'TIMEOUT' : 3
};
Msg.Reserved = function(args) {
  this.rid = null;
  this.timeout = null;
  this.ts = null;
  this.respTopic = null;
  if (args) {
    if (args.rid !== undefined && args.rid !== null) {
      this.rid = args.rid;
    }
    if (args.timeout !== undefined && args.timeout !== null) {
      this.timeout = args.timeout;
    }
    if (args.ts !== undefined && args.ts !== null) {
      this.ts = args.ts;
    }
    if (args.respTopic !== undefined && args.respTopic !== null) {
      this.respTopic = args.respTopic;
    }
  }
};
Msg.Reserved.prototype = {};
Msg.Reserved.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.rid = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.timeout = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.ts = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.respTopic = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Msg.Reserved.prototype.write = function(output) {
  output.writeStructBegin('Reserved');
  if (this.rid !== null && this.rid !== undefined) {
    output.writeFieldBegin('rid', Thrift.Type.STRING, 1);
    output.writeString(this.rid);
    output.writeFieldEnd();
  }
  if (this.timeout !== null && this.timeout !== undefined) {
    output.writeFieldBegin('timeout', Thrift.Type.I32, 2);
    output.writeI32(this.timeout);
    output.writeFieldEnd();
  }
  if (this.ts !== null && this.ts !== undefined) {
    output.writeFieldBegin('ts', Thrift.Type.I64, 3);
    output.writeI64(this.ts);
    output.writeFieldEnd();
  }
  if (this.respTopic !== null && this.respTopic !== undefined) {
    output.writeFieldBegin('respTopic', Thrift.Type.STRING, 4);
    output.writeString(this.respTopic);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Msg.Msg = function(args) {
  this.type = null;
  this.value = null;
  this.reqId = null;
  this.sessionId = null;
  this.sessionToken = null;
  this.reserved = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
    if (args.reqId !== undefined && args.reqId !== null) {
      this.reqId = args.reqId;
    }
    if (args.sessionId !== undefined && args.sessionId !== null) {
      this.sessionId = args.sessionId;
    }
    if (args.sessionToken !== undefined && args.sessionToken !== null) {
      this.sessionToken = args.sessionToken;
    }
    if (args.reserved !== undefined && args.reserved !== null) {
      this.reserved = new Msg.Reserved(args.reserved);
    }
  }
};
Msg.Msg.prototype = {};
Msg.Msg.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readBinary().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.reqId = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.sessionId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.sessionToken = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.reserved = new Msg.Reserved();
        this.reserved.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Msg.Msg.prototype.write = function(output) {
  output.writeStructBegin('Msg');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 2);
    output.writeBinary(this.value);
    output.writeFieldEnd();
  }
  if (this.reqId !== null && this.reqId !== undefined) {
    output.writeFieldBegin('reqId', Thrift.Type.I32, 3);
    output.writeI32(this.reqId);
    output.writeFieldEnd();
  }
  if (this.sessionId !== null && this.sessionId !== undefined) {
    output.writeFieldBegin('sessionId', Thrift.Type.STRING, 4);
    output.writeString(this.sessionId);
    output.writeFieldEnd();
  }
  if (this.sessionToken !== null && this.sessionToken !== undefined) {
    output.writeFieldBegin('sessionToken', Thrift.Type.STRING, 5);
    output.writeString(this.sessionToken);
    output.writeFieldEnd();
  }
  if (this.reserved !== null && this.reserved !== undefined) {
    output.writeFieldBegin('reserved', Thrift.Type.STRUCT, 6);
    this.reserved.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Msg.ErrorMsg = function(args) {
  this.code = null;
  this.msgType = null;
  this.errorDesc = null;
  if (args) {
    if (args.code !== undefined && args.code !== null) {
      this.code = args.code;
    }
    if (args.msgType !== undefined && args.msgType !== null) {
      this.msgType = args.msgType;
    }
    if (args.errorDesc !== undefined && args.errorDesc !== null) {
      this.errorDesc = args.errorDesc;
    }
  }
};
Msg.ErrorMsg.prototype = {};
Msg.ErrorMsg.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.code = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.msgType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.errorDesc = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Msg.ErrorMsg.prototype.write = function(output) {
  output.writeStructBegin('ErrorMsg');
  if (this.code !== null && this.code !== undefined) {
    output.writeFieldBegin('code', Thrift.Type.I32, 1);
    output.writeI32(this.code);
    output.writeFieldEnd();
  }
  if (this.msgType !== null && this.msgType !== undefined) {
    output.writeFieldBegin('msgType', Thrift.Type.I32, 2);
    output.writeI32(this.msgType);
    output.writeFieldEnd();
  }
  if (this.errorDesc !== null && this.errorDesc !== undefined) {
    output.writeFieldBegin('errorDesc', Thrift.Type.STRING, 3);
    output.writeString(this.errorDesc);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

