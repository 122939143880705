//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


if (typeof Publish === 'undefined') {
  Publish = {};
}
Publish.PublishType = {
  'ARTICLE' : 1,
  'REVIEW' : 2,
  'COMMENT' : 100
};
Publish.PublishStatus = {
  'PENDING' : 1,
  'BC_COMPLETED' : 4,
  'BC_CANCELED' : 5,
  'BC_RETRACTED' : 6
};
Publish.EditStatus = {
  'PENDING' : 1,
  'REVIEW' : 50,
  'COMPLETED' : 100
};
Publish.SourceType = {
  'PDF' : 1,
  'ARCHIVE' : 2,
  'OTHER' : 1000
};
Publish.SocialOperation = {
  'SET_LIKE' : 1,
  'SET_DISLIKE' : 2,
  'RESET_LIKE' : 101,
  'RESET_DISLIKE' : 102
};
Publish.InitiateClaimArticle = function(args) {
  this.articleId = null;
  this.userId = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
Publish.InitiateClaimArticle.prototype = {};
Publish.InitiateClaimArticle.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.InitiateClaimArticle.prototype.write = function(output) {
  output.writeStructBegin('InitiateClaimArticle');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 2);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateClaimArticle = function(args) {
  this.articleId = null;
  this.bcAddress = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = Thrift.copyList(args.bcAddress, [null]);
    }
  }
};
Publish.UpdateClaimArticle.prototype = {};
Publish.UpdateClaimArticle.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.bcAddress = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = input.readString().value;
          this.bcAddress.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateClaimArticle.prototype.write = function(output) {
  output.writeStructBegin('UpdateClaimArticle');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.bcAddress.length);
    for (var iter7 in this.bcAddress)
    {
      if (this.bcAddress.hasOwnProperty(iter7))
      {
        iter7 = this.bcAddress[iter7];
        output.writeString(iter7);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.Counters = function(args) {
  this.likes = null;
  this.dislikes = null;
  this.citations = null;
  if (args) {
    if (args.likes !== undefined && args.likes !== null) {
      this.likes = args.likes;
    }
    if (args.dislikes !== undefined && args.dislikes !== null) {
      this.dislikes = args.dislikes;
    }
    if (args.citations !== undefined && args.citations !== null) {
      this.citations = args.citations;
    }
  }
};
Publish.Counters.prototype = {};
Publish.Counters.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.likes = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.dislikes = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.citations = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.Counters.prototype.write = function(output) {
  output.writeStructBegin('Counters');
  if (this.likes !== null && this.likes !== undefined) {
    output.writeFieldBegin('likes', Thrift.Type.I32, 1);
    output.writeI32(this.likes);
    output.writeFieldEnd();
  }
  if (this.dislikes !== null && this.dislikes !== undefined) {
    output.writeFieldBegin('dislikes', Thrift.Type.I32, 2);
    output.writeI32(this.dislikes);
    output.writeFieldEnd();
  }
  if (this.citations !== null && this.citations !== undefined) {
    output.writeFieldBegin('citations', Thrift.Type.I32, 3);
    output.writeI32(this.citations);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetMyPublishReq = function(args) {
  this.userId = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
  }
};
Publish.GetMyPublishReq.prototype = {};
Publish.GetMyPublishReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetMyPublishReq.prototype.write = function(output) {
  output.writeStructBegin('GetMyPublishReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.PublishEntity = function(args) {
  this.id = null;
  this.status = null;
  this.type = null;
  this.articleId = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.userIds = null;
  this.counters = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.userIds !== undefined && args.userIds !== null) {
      this.userIds = Thrift.copyList(args.userIds, [null]);
    }
    if (args.counters !== undefined && args.counters !== null) {
      this.counters = new Publish.Counters(args.counters);
    }
  }
};
Publish.PublishEntity.prototype = {};
Publish.PublishEntity.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size8 = 0;
        var _rtmp312;
        this.userIds = [];
        var _etype11 = 0;
        _rtmp312 = input.readListBegin();
        _etype11 = _rtmp312.etype;
        _size8 = _rtmp312.size;
        for (var _i13 = 0; _i13 < _size8; ++_i13)
        {
          var elem14 = null;
          elem14 = input.readString().value;
          this.userIds.push(elem14);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.counters = new Publish.Counters();
        this.counters.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.PublishEntity.prototype.write = function(output) {
  output.writeStructBegin('PublishEntity');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 2);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 3);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 4);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 5);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 6);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.userIds !== null && this.userIds !== undefined) {
    output.writeFieldBegin('userIds', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRING, this.userIds.length);
    for (var iter15 in this.userIds)
    {
      if (this.userIds.hasOwnProperty(iter15))
      {
        iter15 = this.userIds[iter15];
        output.writeString(iter15);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.counters !== null && this.counters !== undefined) {
    output.writeFieldBegin('counters', Thrift.Type.STRUCT, 8);
    this.counters.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetMyPublishResp = function(args) {
  this.userId = null;
  this.article = null;
  this.review = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.article !== undefined && args.article !== null) {
      this.article = Thrift.copyList(args.article, [Publish.PublishEntity]);
    }
    if (args.review !== undefined && args.review !== null) {
      this.review = Thrift.copyList(args.review, [Publish.PublishEntity]);
    }
  }
};
Publish.GetMyPublishResp.prototype = {};
Publish.GetMyPublishResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size16 = 0;
        var _rtmp320;
        this.article = [];
        var _etype19 = 0;
        _rtmp320 = input.readListBegin();
        _etype19 = _rtmp320.etype;
        _size16 = _rtmp320.size;
        for (var _i21 = 0; _i21 < _size16; ++_i21)
        {
          var elem22 = null;
          elem22 = new Publish.PublishEntity();
          elem22.read(input);
          this.article.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size23 = 0;
        var _rtmp327;
        this.review = [];
        var _etype26 = 0;
        _rtmp327 = input.readListBegin();
        _etype26 = _rtmp327.etype;
        _size23 = _rtmp327.size;
        for (var _i28 = 0; _i28 < _size23; ++_i28)
        {
          var elem29 = null;
          elem29 = new Publish.PublishEntity();
          elem29.read(input);
          this.review.push(elem29);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetMyPublishResp.prototype.write = function(output) {
  output.writeStructBegin('GetMyPublishResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.article !== null && this.article !== undefined) {
    output.writeFieldBegin('article', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.article.length);
    for (var iter30 in this.article)
    {
      if (this.article.hasOwnProperty(iter30))
      {
        iter30 = this.article[iter30];
        iter30.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.review !== null && this.review !== undefined) {
    output.writeFieldBegin('review', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.review.length);
    for (var iter31 in this.review)
    {
      if (this.review.hasOwnProperty(iter31))
      {
        iter31 = this.review[iter31];
        iter31.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.BcPublishEntityUpdate = function(args) {
  this.id = null;
  this.type = null;
  this.status = null;
  this.bcAuthorAddress = null;
  this.id2 = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.bcAuthorAddress !== undefined && args.bcAuthorAddress !== null) {
      this.bcAuthorAddress = Thrift.copyList(args.bcAuthorAddress, [null]);
    }
    if (args.id2 !== undefined && args.id2 !== null) {
      this.id2 = args.id2;
    }
  }
};
Publish.BcPublishEntityUpdate.prototype = {};
Publish.BcPublishEntityUpdate.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size32 = 0;
        var _rtmp336;
        this.bcAuthorAddress = [];
        var _etype35 = 0;
        _rtmp336 = input.readListBegin();
        _etype35 = _rtmp336.etype;
        _size32 = _rtmp336.size;
        for (var _i37 = 0; _i37 < _size32; ++_i37)
        {
          var elem38 = null;
          elem38 = input.readString().value;
          this.bcAuthorAddress.push(elem38);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.id2 = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.BcPublishEntityUpdate.prototype.write = function(output) {
  output.writeStructBegin('BcPublishEntityUpdate');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 3);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  if (this.bcAuthorAddress !== null && this.bcAuthorAddress !== undefined) {
    output.writeFieldBegin('bcAuthorAddress', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRING, this.bcAuthorAddress.length);
    for (var iter39 in this.bcAuthorAddress)
    {
      if (this.bcAuthorAddress.hasOwnProperty(iter39))
      {
        iter39 = this.bcAuthorAddress[iter39];
        output.writeString(iter39);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.id2 !== null && this.id2 !== undefined) {
    output.writeFieldBegin('id2', Thrift.Type.STRING, 5);
    output.writeString(this.id2);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.Review = function(args) {
  this.reviewId = null;
  this.userId = null;
  this.articleId = null;
  this.text = null;
  this.publishStatus = null;
  this.editStatus = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.counters = null;
  if (args) {
    if (args.reviewId !== undefined && args.reviewId !== null) {
      this.reviewId = args.reviewId;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.text !== undefined && args.text !== null) {
      this.text = args.text;
    }
    if (args.publishStatus !== undefined && args.publishStatus !== null) {
      this.publishStatus = args.publishStatus;
    }
    if (args.editStatus !== undefined && args.editStatus !== null) {
      this.editStatus = args.editStatus;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.counters !== undefined && args.counters !== null) {
      this.counters = new Publish.Counters(args.counters);
    }
  }
};
Publish.Review.prototype = {};
Publish.Review.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.reviewId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.text = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I32) {
        this.publishStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.editStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.counters = new Publish.Counters();
        this.counters.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.Review.prototype.write = function(output) {
  output.writeStructBegin('Review');
  if (this.reviewId !== null && this.reviewId !== undefined) {
    output.writeFieldBegin('reviewId', Thrift.Type.STRING, 1);
    output.writeString(this.reviewId);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 2);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 3);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.text !== null && this.text !== undefined) {
    output.writeFieldBegin('text', Thrift.Type.STRING, 4);
    output.writeString(this.text);
    output.writeFieldEnd();
  }
  if (this.publishStatus !== null && this.publishStatus !== undefined) {
    output.writeFieldBegin('publishStatus', Thrift.Type.I32, 5);
    output.writeI32(this.publishStatus);
    output.writeFieldEnd();
  }
  if (this.editStatus !== null && this.editStatus !== undefined) {
    output.writeFieldBegin('editStatus', Thrift.Type.I32, 6);
    output.writeI32(this.editStatus);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 7);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 8);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.counters !== null && this.counters !== undefined) {
    output.writeFieldBegin('counters', Thrift.Type.STRUCT, 9);
    this.counters.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateReviewReq = function(args) {
  this.review = null;
  if (args) {
    if (args.review !== undefined && args.review !== null) {
      this.review = new Publish.Review(args.review);
    }
  }
};
Publish.UpdateReviewReq.prototype = {};
Publish.UpdateReviewReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.review = new Publish.Review();
        this.review.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateReviewReq.prototype.write = function(output) {
  output.writeStructBegin('UpdateReviewReq');
  if (this.review !== null && this.review !== undefined) {
    output.writeFieldBegin('review', Thrift.Type.STRUCT, 1);
    this.review.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateReviewResp = function(args) {
  this.reviewId = null;
  this.editStatus = null;
  this.publishStatus = null;
  this.updatedTime = null;
  if (args) {
    if (args.reviewId !== undefined && args.reviewId !== null) {
      this.reviewId = args.reviewId;
    }
    if (args.editStatus !== undefined && args.editStatus !== null) {
      this.editStatus = args.editStatus;
    }
    if (args.publishStatus !== undefined && args.publishStatus !== null) {
      this.publishStatus = args.publishStatus;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
  }
};
Publish.UpdateReviewResp.prototype = {};
Publish.UpdateReviewResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.reviewId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.editStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.publishStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateReviewResp.prototype.write = function(output) {
  output.writeStructBegin('UpdateReviewResp');
  if (this.reviewId !== null && this.reviewId !== undefined) {
    output.writeFieldBegin('reviewId', Thrift.Type.STRING, 1);
    output.writeString(this.reviewId);
    output.writeFieldEnd();
  }
  if (this.editStatus !== null && this.editStatus !== undefined) {
    output.writeFieldBegin('editStatus', Thrift.Type.I32, 2);
    output.writeI32(this.editStatus);
    output.writeFieldEnd();
  }
  if (this.publishStatus !== null && this.publishStatus !== undefined) {
    output.writeFieldBegin('publishStatus', Thrift.Type.I32, 3);
    output.writeI32(this.publishStatus);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 4);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetReviewReq = function(args) {
  this.userId = null;
  this.reviewIds = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.reviewIds !== undefined && args.reviewIds !== null) {
      this.reviewIds = Thrift.copyList(args.reviewIds, [null]);
    }
  }
};
Publish.GetReviewReq.prototype = {};
Publish.GetReviewReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size40 = 0;
        var _rtmp344;
        this.reviewIds = [];
        var _etype43 = 0;
        _rtmp344 = input.readListBegin();
        _etype43 = _rtmp344.etype;
        _size40 = _rtmp344.size;
        for (var _i45 = 0; _i45 < _size40; ++_i45)
        {
          var elem46 = null;
          elem46 = input.readString().value;
          this.reviewIds.push(elem46);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetReviewReq.prototype.write = function(output) {
  output.writeStructBegin('GetReviewReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.reviewIds !== null && this.reviewIds !== undefined) {
    output.writeFieldBegin('reviewIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.reviewIds.length);
    for (var iter47 in this.reviewIds)
    {
      if (this.reviewIds.hasOwnProperty(iter47))
      {
        iter47 = this.reviewIds[iter47];
        output.writeString(iter47);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetReviewResp = function(args) {
  this.reviews = null;
  if (args) {
    if (args.reviews !== undefined && args.reviews !== null) {
      this.reviews = Thrift.copyList(args.reviews, [Publish.Review]);
    }
  }
};
Publish.GetReviewResp.prototype = {};
Publish.GetReviewResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size48 = 0;
        var _rtmp352;
        this.reviews = [];
        var _etype51 = 0;
        _rtmp352 = input.readListBegin();
        _etype51 = _rtmp352.etype;
        _size48 = _rtmp352.size;
        for (var _i53 = 0; _i53 < _size48; ++_i53)
        {
          var elem54 = null;
          elem54 = new Publish.Review();
          elem54.read(input);
          this.reviews.push(elem54);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetReviewResp.prototype.write = function(output) {
  output.writeStructBegin('GetReviewResp');
  if (this.reviews !== null && this.reviews !== undefined) {
    output.writeFieldBegin('reviews', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.reviews.length);
    for (var iter55 in this.reviews)
    {
      if (this.reviews.hasOwnProperty(iter55))
      {
        iter55 = this.reviews[iter55];
        iter55.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetPublishReviewReq = function(args) {
  this.articleId = null;
  this.reviewIds = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.reviewIds !== undefined && args.reviewIds !== null) {
      this.reviewIds = Thrift.copyList(args.reviewIds, [null]);
    }
  }
};
Publish.GetPublishReviewReq.prototype = {};
Publish.GetPublishReviewReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size56 = 0;
        var _rtmp360;
        this.reviewIds = [];
        var _etype59 = 0;
        _rtmp360 = input.readListBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = input.readString().value;
          this.reviewIds.push(elem62);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetPublishReviewReq.prototype.write = function(output) {
  output.writeStructBegin('GetPublishReviewReq');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.reviewIds !== null && this.reviewIds !== undefined) {
    output.writeFieldBegin('reviewIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.reviewIds.length);
    for (var iter63 in this.reviewIds)
    {
      if (this.reviewIds.hasOwnProperty(iter63))
      {
        iter63 = this.reviewIds[iter63];
        output.writeString(iter63);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetPublishReviewResp = function(args) {
  this.reviews = null;
  if (args) {
    if (args.reviews !== undefined && args.reviews !== null) {
      this.reviews = Thrift.copyList(args.reviews, [Publish.Review]);
    }
  }
};
Publish.GetPublishReviewResp.prototype = {};
Publish.GetPublishReviewResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size64 = 0;
        var _rtmp368;
        this.reviews = [];
        var _etype67 = 0;
        _rtmp368 = input.readListBegin();
        _etype67 = _rtmp368.etype;
        _size64 = _rtmp368.size;
        for (var _i69 = 0; _i69 < _size64; ++_i69)
        {
          var elem70 = null;
          elem70 = new Publish.Review();
          elem70.read(input);
          this.reviews.push(elem70);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetPublishReviewResp.prototype.write = function(output) {
  output.writeStructBegin('GetPublishReviewResp');
  if (this.reviews !== null && this.reviews !== undefined) {
    output.writeFieldBegin('reviews', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.reviews.length);
    for (var iter71 in this.reviews)
    {
      if (this.reviews.hasOwnProperty(iter71))
      {
        iter71 = this.reviews[iter71];
        iter71.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.Affiliation = function(args) {
  this.company = null;
  this.companyId = null;
  if (args) {
    if (args.company !== undefined && args.company !== null) {
      this.company = args.company;
    }
    if (args.companyId !== undefined && args.companyId !== null) {
      this.companyId = args.companyId;
    }
  }
};
Publish.Affiliation.prototype = {};
Publish.Affiliation.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.company = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.companyId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.Affiliation.prototype.write = function(output) {
  output.writeStructBegin('Affiliation');
  if (this.company !== null && this.company !== undefined) {
    output.writeFieldBegin('company', Thrift.Type.STRING, 1);
    output.writeString(this.company);
    output.writeFieldEnd();
  }
  if (this.companyId !== null && this.companyId !== undefined) {
    output.writeFieldBegin('companyId', Thrift.Type.STRING, 2);
    output.writeString(this.companyId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.Author = function(args) {
  this.firstName = null;
  this.lastName = null;
  this.userId = null;
  this.affiliation = null;
  if (args) {
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.affiliation !== undefined && args.affiliation !== null) {
      this.affiliation = new Publish.Affiliation(args.affiliation);
    }
  }
};
Publish.Author.prototype = {};
Publish.Author.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.affiliation = new Publish.Affiliation();
        this.affiliation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.Author.prototype.write = function(output) {
  output.writeStructBegin('Author');
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 1);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 2);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 3);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.affiliation !== null && this.affiliation !== undefined) {
    output.writeFieldBegin('affiliation', Thrift.Type.STRUCT, 4);
    this.affiliation.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.SourceLink = function(args) {
  this.name = null;
  this.url = null;
  this.type = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
  }
};
Publish.SourceLink.prototype = {};
Publish.SourceLink.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.SourceLink.prototype.write = function(output) {
  output.writeStructBegin('SourceLink');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 2);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 3);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.Article = function(args) {
  this.articleId = null;
  this.title = null;
  this.summary = null;
  this.keywords = null;
  this.fos = null;
  this.authors = null;
  this.sources = null;
  this.publishStatus = null;
  this.editStatus = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.counters = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.summary !== undefined && args.summary !== null) {
      this.summary = args.summary;
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.fos !== undefined && args.fos !== null) {
      this.fos = Thrift.copyList(args.fos, [null]);
    }
    if (args.authors !== undefined && args.authors !== null) {
      this.authors = Thrift.copyList(args.authors, [Publish.Author]);
    }
    if (args.sources !== undefined && args.sources !== null) {
      this.sources = Thrift.copyList(args.sources, [Publish.SourceLink]);
    }
    if (args.publishStatus !== undefined && args.publishStatus !== null) {
      this.publishStatus = args.publishStatus;
    }
    if (args.editStatus !== undefined && args.editStatus !== null) {
      this.editStatus = args.editStatus;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.counters !== undefined && args.counters !== null) {
      this.counters = new Publish.Counters(args.counters);
    }
  }
};
Publish.Article.prototype = {};
Publish.Article.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.summary = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size72 = 0;
        var _rtmp376;
        this.keywords = [];
        var _etype75 = 0;
        _rtmp376 = input.readListBegin();
        _etype75 = _rtmp376.etype;
        _size72 = _rtmp376.size;
        for (var _i77 = 0; _i77 < _size72; ++_i77)
        {
          var elem78 = null;
          elem78 = input.readString().value;
          this.keywords.push(elem78);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size79 = 0;
        var _rtmp383;
        this.fos = [];
        var _etype82 = 0;
        _rtmp383 = input.readListBegin();
        _etype82 = _rtmp383.etype;
        _size79 = _rtmp383.size;
        for (var _i84 = 0; _i84 < _size79; ++_i84)
        {
          var elem85 = null;
          elem85 = input.readString().value;
          this.fos.push(elem85);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size86 = 0;
        var _rtmp390;
        this.authors = [];
        var _etype89 = 0;
        _rtmp390 = input.readListBegin();
        _etype89 = _rtmp390.etype;
        _size86 = _rtmp390.size;
        for (var _i91 = 0; _i91 < _size86; ++_i91)
        {
          var elem92 = null;
          elem92 = new Publish.Author();
          elem92.read(input);
          this.authors.push(elem92);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        var _size93 = 0;
        var _rtmp397;
        this.sources = [];
        var _etype96 = 0;
        _rtmp397 = input.readListBegin();
        _etype96 = _rtmp397.etype;
        _size93 = _rtmp397.size;
        for (var _i98 = 0; _i98 < _size93; ++_i98)
        {
          var elem99 = null;
          elem99 = new Publish.SourceLink();
          elem99.read(input);
          this.sources.push(elem99);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.publishStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.editStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.counters = new Publish.Counters();
        this.counters.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.Article.prototype.write = function(output) {
  output.writeStructBegin('Article');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 2);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.summary !== null && this.summary !== undefined) {
    output.writeFieldBegin('summary', Thrift.Type.STRING, 3);
    output.writeString(this.summary);
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter100 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter100))
      {
        iter100 = this.keywords[iter100];
        output.writeString(iter100);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.fos !== null && this.fos !== undefined) {
    output.writeFieldBegin('fos', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRING, this.fos.length);
    for (var iter101 in this.fos)
    {
      if (this.fos.hasOwnProperty(iter101))
      {
        iter101 = this.fos[iter101];
        output.writeString(iter101);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.authors !== null && this.authors !== undefined) {
    output.writeFieldBegin('authors', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.authors.length);
    for (var iter102 in this.authors)
    {
      if (this.authors.hasOwnProperty(iter102))
      {
        iter102 = this.authors[iter102];
        iter102.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.sources !== null && this.sources !== undefined) {
    output.writeFieldBegin('sources', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.sources.length);
    for (var iter103 in this.sources)
    {
      if (this.sources.hasOwnProperty(iter103))
      {
        iter103 = this.sources[iter103];
        iter103.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.publishStatus !== null && this.publishStatus !== undefined) {
    output.writeFieldBegin('publishStatus', Thrift.Type.I32, 8);
    output.writeI32(this.publishStatus);
    output.writeFieldEnd();
  }
  if (this.editStatus !== null && this.editStatus !== undefined) {
    output.writeFieldBegin('editStatus', Thrift.Type.I32, 9);
    output.writeI32(this.editStatus);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 10);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 11);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.counters !== null && this.counters !== undefined) {
    output.writeFieldBegin('counters', Thrift.Type.STRUCT, 12);
    this.counters.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateArticleReq = function(args) {
  this.article = null;
  if (args) {
    if (args.article !== undefined && args.article !== null) {
      this.article = new Publish.Article(args.article);
    }
  }
};
Publish.UpdateArticleReq.prototype = {};
Publish.UpdateArticleReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.article = new Publish.Article();
        this.article.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateArticleReq.prototype.write = function(output) {
  output.writeStructBegin('UpdateArticleReq');
  if (this.article !== null && this.article !== undefined) {
    output.writeFieldBegin('article', Thrift.Type.STRUCT, 1);
    this.article.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateArticleResp = function(args) {
  this.articleId = null;
  this.editStatus = null;
  this.publishStatus = null;
  this.updatedTime = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.editStatus !== undefined && args.editStatus !== null) {
      this.editStatus = args.editStatus;
    }
    if (args.publishStatus !== undefined && args.publishStatus !== null) {
      this.publishStatus = args.publishStatus;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
  }
};
Publish.UpdateArticleResp.prototype = {};
Publish.UpdateArticleResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.editStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.publishStatus = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateArticleResp.prototype.write = function(output) {
  output.writeStructBegin('UpdateArticleResp');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.editStatus !== null && this.editStatus !== undefined) {
    output.writeFieldBegin('editStatus', Thrift.Type.I32, 2);
    output.writeI32(this.editStatus);
    output.writeFieldEnd();
  }
  if (this.publishStatus !== null && this.publishStatus !== undefined) {
    output.writeFieldBegin('publishStatus', Thrift.Type.I32, 3);
    output.writeI32(this.publishStatus);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 4);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetArticleReq = function(args) {
  this.userId = null;
  this.articleIds = null;
  this.updatedTime = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.articleIds !== undefined && args.articleIds !== null) {
      this.articleIds = Thrift.copyList(args.articleIds, [null]);
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
  }
};
Publish.GetArticleReq.prototype = {};
Publish.GetArticleReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size104 = 0;
        var _rtmp3108;
        this.articleIds = [];
        var _etype107 = 0;
        _rtmp3108 = input.readListBegin();
        _etype107 = _rtmp3108.etype;
        _size104 = _rtmp3108.size;
        for (var _i109 = 0; _i109 < _size104; ++_i109)
        {
          var elem110 = null;
          elem110 = input.readString().value;
          this.articleIds.push(elem110);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetArticleReq.prototype.write = function(output) {
  output.writeStructBegin('GetArticleReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.articleIds !== null && this.articleIds !== undefined) {
    output.writeFieldBegin('articleIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.articleIds.length);
    for (var iter111 in this.articleIds)
    {
      if (this.articleIds.hasOwnProperty(iter111))
      {
        iter111 = this.articleIds[iter111];
        output.writeString(iter111);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 3);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetArticleResp = function(args) {
  this.articles = null;
  if (args) {
    if (args.articles !== undefined && args.articles !== null) {
      this.articles = Thrift.copyList(args.articles, [Publish.Article]);
    }
  }
};
Publish.GetArticleResp.prototype = {};
Publish.GetArticleResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size112 = 0;
        var _rtmp3116;
        this.articles = [];
        var _etype115 = 0;
        _rtmp3116 = input.readListBegin();
        _etype115 = _rtmp3116.etype;
        _size112 = _rtmp3116.size;
        for (var _i117 = 0; _i117 < _size112; ++_i117)
        {
          var elem118 = null;
          elem118 = new Publish.Article();
          elem118.read(input);
          this.articles.push(elem118);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetArticleResp.prototype.write = function(output) {
  output.writeStructBegin('GetArticleResp');
  if (this.articles !== null && this.articles !== undefined) {
    output.writeFieldBegin('articles', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.articles.length);
    for (var iter119 in this.articles)
    {
      if (this.articles.hasOwnProperty(iter119))
      {
        iter119 = this.articles[iter119];
        iter119.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetPublishArticleReq = function(args) {
  this.userId = null;
  this.articleIds = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.articleIds !== undefined && args.articleIds !== null) {
      this.articleIds = Thrift.copyList(args.articleIds, [null]);
    }
  }
};
Publish.GetPublishArticleReq.prototype = {};
Publish.GetPublishArticleReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size120 = 0;
        var _rtmp3124;
        this.articleIds = [];
        var _etype123 = 0;
        _rtmp3124 = input.readListBegin();
        _etype123 = _rtmp3124.etype;
        _size120 = _rtmp3124.size;
        for (var _i125 = 0; _i125 < _size120; ++_i125)
        {
          var elem126 = null;
          elem126 = input.readString().value;
          this.articleIds.push(elem126);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetPublishArticleReq.prototype.write = function(output) {
  output.writeStructBegin('GetPublishArticleReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.articleIds !== null && this.articleIds !== undefined) {
    output.writeFieldBegin('articleIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.articleIds.length);
    for (var iter127 in this.articleIds)
    {
      if (this.articleIds.hasOwnProperty(iter127))
      {
        iter127 = this.articleIds[iter127];
        output.writeString(iter127);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetPublishArticleResp = function(args) {
  this.articles = null;
  if (args) {
    if (args.articles !== undefined && args.articles !== null) {
      this.articles = Thrift.copyList(args.articles, [Publish.Article]);
    }
  }
};
Publish.GetPublishArticleResp.prototype = {};
Publish.GetPublishArticleResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size128 = 0;
        var _rtmp3132;
        this.articles = [];
        var _etype131 = 0;
        _rtmp3132 = input.readListBegin();
        _etype131 = _rtmp3132.etype;
        _size128 = _rtmp3132.size;
        for (var _i133 = 0; _i133 < _size128; ++_i133)
        {
          var elem134 = null;
          elem134 = new Publish.Article();
          elem134.read(input);
          this.articles.push(elem134);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetPublishArticleResp.prototype.write = function(output) {
  output.writeStructBegin('GetPublishArticleResp');
  if (this.articles !== null && this.articles !== undefined) {
    output.writeFieldBegin('articles', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.articles.length);
    for (var iter135 in this.articles)
    {
      if (this.articles.hasOwnProperty(iter135))
      {
        iter135 = this.articles[iter135];
        iter135.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.DeleteArticleReq = function(args) {
  this.articleId = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
  }
};
Publish.DeleteArticleReq.prototype = {};
Publish.DeleteArticleReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.DeleteArticleReq.prototype.write = function(output) {
  output.writeStructBegin('DeleteArticleReq');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.DeleteArticleResp = function(args) {
  this.articleId = null;
  this.err = null;
  if (args) {
    if (args.articleId !== undefined && args.articleId !== null) {
      this.articleId = args.articleId;
    }
    if (args.err !== undefined && args.err !== null) {
      this.err = args.err;
    }
  }
};
Publish.DeleteArticleResp.prototype = {};
Publish.DeleteArticleResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.articleId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.err = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.DeleteArticleResp.prototype.write = function(output) {
  output.writeStructBegin('DeleteArticleResp');
  if (this.articleId !== null && this.articleId !== undefined) {
    output.writeFieldBegin('articleId', Thrift.Type.STRING, 1);
    output.writeString(this.articleId);
    output.writeFieldEnd();
  }
  if (this.err !== null && this.err !== undefined) {
    output.writeFieldBegin('err', Thrift.Type.STRING, 2);
    output.writeString(this.err);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.PublishDummy = function(args) {
  this.dummy = null;
  if (args) {
    if (args.dummy !== undefined && args.dummy !== null) {
      this.dummy = args.dummy;
    }
  }
};
Publish.PublishDummy.prototype = {};
Publish.PublishDummy.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.dummy = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.PublishDummy.prototype.write = function(output) {
  output.writeStructBegin('PublishDummy');
  if (this.dummy !== null && this.dummy !== undefined) {
    output.writeFieldBegin('dummy', Thrift.Type.STRING, 1);
    output.writeString(this.dummy);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.SetSocialOperationReq = function(args) {
  this.userId = null;
  this.entityId = null;
  this.oper = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.oper !== undefined && args.oper !== null) {
      this.oper = args.oper;
    }
  }
};
Publish.SetSocialOperationReq.prototype = {};
Publish.SetSocialOperationReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.oper = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.SetSocialOperationReq.prototype.write = function(output) {
  output.writeStructBegin('SetSocialOperationReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 2);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.oper !== null && this.oper !== undefined) {
    output.writeFieldBegin('oper', Thrift.Type.I32, 3);
    output.writeI32(this.oper);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.SetSocialOperationResp = function(args) {
  this.userId = null;
  this.entityId = null;
  this.err = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.err !== undefined && args.err !== null) {
      this.err = args.err;
    }
  }
};
Publish.SetSocialOperationResp.prototype = {};
Publish.SetSocialOperationResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.err = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.SetSocialOperationResp.prototype.write = function(output) {
  output.writeStructBegin('SetSocialOperationResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 2);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.err !== null && this.err !== undefined) {
    output.writeFieldBegin('err', Thrift.Type.STRING, 3);
    output.writeString(this.err);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetUserIdsForSocialOperationReq = function(args) {
  this.entityId = null;
  this.oper = null;
  this.pageSize = null;
  this.pageState = null;
  if (args) {
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.oper !== undefined && args.oper !== null) {
      this.oper = args.oper;
    }
    if (args.pageSize !== undefined && args.pageSize !== null) {
      this.pageSize = args.pageSize;
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
Publish.GetUserIdsForSocialOperationReq.prototype = {};
Publish.GetUserIdsForSocialOperationReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.oper = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I16) {
        this.pageSize = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetUserIdsForSocialOperationReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserIdsForSocialOperationReq');
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 1);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.oper !== null && this.oper !== undefined) {
    output.writeFieldBegin('oper', Thrift.Type.I32, 2);
    output.writeI32(this.oper);
    output.writeFieldEnd();
  }
  if (this.pageSize !== null && this.pageSize !== undefined) {
    output.writeFieldBegin('pageSize', Thrift.Type.I16, 3);
    output.writeI16(this.pageSize);
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 4);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UserIdData = function(args) {
  this.userId = null;
  this.createdTime = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
  }
};
Publish.UserIdData.prototype = {};
Publish.UserIdData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UserIdData.prototype.write = function(output) {
  output.writeStructBegin('UserIdData');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 2);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetUserIdsForSocialOperationResp = function(args) {
  this.entityId = null;
  this.pageState = null;
  this.users = null;
  if (args) {
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [Publish.UserIdData]);
    }
  }
};
Publish.GetUserIdsForSocialOperationResp.prototype = {};
Publish.GetUserIdsForSocialOperationResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size136 = 0;
        var _rtmp3140;
        this.users = [];
        var _etype139 = 0;
        _rtmp3140 = input.readListBegin();
        _etype139 = _rtmp3140.etype;
        _size136 = _rtmp3140.size;
        for (var _i141 = 0; _i141 < _size136; ++_i141)
        {
          var elem142 = null;
          elem142 = new Publish.UserIdData();
          elem142.read(input);
          this.users.push(elem142);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetUserIdsForSocialOperationResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserIdsForSocialOperationResp');
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 1);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 2);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter143 in this.users)
    {
      if (this.users.hasOwnProperty(iter143))
      {
        iter143 = this.users[iter143];
        iter143.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetSocialOperationForUserIdReq = function(args) {
  this.userId = null;
  this.entityIds = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.entityIds !== undefined && args.entityIds !== null) {
      this.entityIds = Thrift.copyList(args.entityIds, [null]);
    }
  }
};
Publish.GetSocialOperationForUserIdReq.prototype = {};
Publish.GetSocialOperationForUserIdReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size144 = 0;
        var _rtmp3148;
        this.entityIds = [];
        var _etype147 = 0;
        _rtmp3148 = input.readListBegin();
        _etype147 = _rtmp3148.etype;
        _size144 = _rtmp3148.size;
        for (var _i149 = 0; _i149 < _size144; ++_i149)
        {
          var elem150 = null;
          elem150 = input.readString().value;
          this.entityIds.push(elem150);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetSocialOperationForUserIdReq.prototype.write = function(output) {
  output.writeStructBegin('GetSocialOperationForUserIdReq');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.entityIds !== null && this.entityIds !== undefined) {
    output.writeFieldBegin('entityIds', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.entityIds.length);
    for (var iter151 in this.entityIds)
    {
      if (this.entityIds.hasOwnProperty(iter151))
      {
        iter151 = this.entityIds[iter151];
        output.writeString(iter151);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.EntityIdOperationData = function(args) {
  this.entityId = null;
  this.oper = null;
  this.createdTime = null;
  if (args) {
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.oper !== undefined && args.oper !== null) {
      this.oper = args.oper;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
  }
};
Publish.EntityIdOperationData.prototype = {};
Publish.EntityIdOperationData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.oper = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.EntityIdOperationData.prototype.write = function(output) {
  output.writeStructBegin('EntityIdOperationData');
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 1);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.oper !== null && this.oper !== undefined) {
    output.writeFieldBegin('oper', Thrift.Type.I32, 2);
    output.writeI32(this.oper);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 3);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetSocialOperationForUserIdResp = function(args) {
  this.userId = null;
  this.data = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.data !== undefined && args.data !== null) {
      this.data = Thrift.copyList(args.data, [Publish.EntityIdOperationData]);
    }
  }
};
Publish.GetSocialOperationForUserIdResp.prototype = {};
Publish.GetSocialOperationForUserIdResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size152 = 0;
        var _rtmp3156;
        this.data = [];
        var _etype155 = 0;
        _rtmp3156 = input.readListBegin();
        _etype155 = _rtmp3156.etype;
        _size152 = _rtmp3156.size;
        for (var _i157 = 0; _i157 < _size152; ++_i157)
        {
          var elem158 = null;
          elem158 = new Publish.EntityIdOperationData();
          elem158.read(input);
          this.data.push(elem158);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetSocialOperationForUserIdResp.prototype.write = function(output) {
  output.writeStructBegin('GetSocialOperationForUserIdResp');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.data !== null && this.data !== undefined) {
    output.writeFieldBegin('data', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.data.length);
    for (var iter159 in this.data)
    {
      if (this.data.hasOwnProperty(iter159))
      {
        iter159 = this.data[iter159];
        iter159.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.Comment = function(args) {
  this.commentId = null;
  this.entityId = null;
  this.entityType = null;
  this.userId = null;
  this.text = null;
  this.createdTime = null;
  this.updatedTime = null;
  this.counters = null;
  if (args) {
    if (args.commentId !== undefined && args.commentId !== null) {
      this.commentId = args.commentId;
    }
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.entityType !== undefined && args.entityType !== null) {
      this.entityType = args.entityType;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.text !== undefined && args.text !== null) {
      this.text = args.text;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
    if (args.counters !== undefined && args.counters !== null) {
      this.counters = new Publish.Counters(args.counters);
    }
  }
};
Publish.Comment.prototype = {};
Publish.Comment.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.commentId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.entityType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.text = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.createdTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.counters = new Publish.Counters();
        this.counters.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.Comment.prototype.write = function(output) {
  output.writeStructBegin('Comment');
  if (this.commentId !== null && this.commentId !== undefined) {
    output.writeFieldBegin('commentId', Thrift.Type.STRING, 1);
    output.writeString(this.commentId);
    output.writeFieldEnd();
  }
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 2);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.entityType !== null && this.entityType !== undefined) {
    output.writeFieldBegin('entityType', Thrift.Type.I32, 3);
    output.writeI32(this.entityType);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 4);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.text !== null && this.text !== undefined) {
    output.writeFieldBegin('text', Thrift.Type.STRING, 5);
    output.writeString(this.text);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.I64, 6);
    output.writeI64(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 7);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  if (this.counters !== null && this.counters !== undefined) {
    output.writeFieldBegin('counters', Thrift.Type.STRUCT, 8);
    this.counters.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateCommentReq = function(args) {
  this.comment = null;
  if (args) {
    if (args.comment !== undefined && args.comment !== null) {
      this.comment = new Publish.Comment(args.comment);
    }
  }
};
Publish.UpdateCommentReq.prototype = {};
Publish.UpdateCommentReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.comment = new Publish.Comment();
        this.comment.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateCommentReq.prototype.write = function(output) {
  output.writeStructBegin('UpdateCommentReq');
  if (this.comment !== null && this.comment !== undefined) {
    output.writeFieldBegin('comment', Thrift.Type.STRUCT, 1);
    this.comment.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.UpdateCommentResp = function(args) {
  this.commentId = null;
  this.updatedTime = null;
  if (args) {
    if (args.commentId !== undefined && args.commentId !== null) {
      this.commentId = args.commentId;
    }
    if (args.updatedTime !== undefined && args.updatedTime !== null) {
      this.updatedTime = args.updatedTime;
    }
  }
};
Publish.UpdateCommentResp.prototype = {};
Publish.UpdateCommentResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.commentId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.updatedTime = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.UpdateCommentResp.prototype.write = function(output) {
  output.writeStructBegin('UpdateCommentResp');
  if (this.commentId !== null && this.commentId !== undefined) {
    output.writeFieldBegin('commentId', Thrift.Type.STRING, 1);
    output.writeString(this.commentId);
    output.writeFieldEnd();
  }
  if (this.updatedTime !== null && this.updatedTime !== undefined) {
    output.writeFieldBegin('updatedTime', Thrift.Type.I64, 2);
    output.writeI64(this.updatedTime);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetCommentReq = function(args) {
  this.commentIds = null;
  if (args) {
    if (args.commentIds !== undefined && args.commentIds !== null) {
      this.commentIds = Thrift.copyList(args.commentIds, [null]);
    }
  }
};
Publish.GetCommentReq.prototype = {};
Publish.GetCommentReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size160 = 0;
        var _rtmp3164;
        this.commentIds = [];
        var _etype163 = 0;
        _rtmp3164 = input.readListBegin();
        _etype163 = _rtmp3164.etype;
        _size160 = _rtmp3164.size;
        for (var _i165 = 0; _i165 < _size160; ++_i165)
        {
          var elem166 = null;
          elem166 = input.readString().value;
          this.commentIds.push(elem166);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetCommentReq.prototype.write = function(output) {
  output.writeStructBegin('GetCommentReq');
  if (this.commentIds !== null && this.commentIds !== undefined) {
    output.writeFieldBegin('commentIds', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.commentIds.length);
    for (var iter167 in this.commentIds)
    {
      if (this.commentIds.hasOwnProperty(iter167))
      {
        iter167 = this.commentIds[iter167];
        output.writeString(iter167);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetCommentResp = function(args) {
  this.comments = null;
  if (args) {
    if (args.comments !== undefined && args.comments !== null) {
      this.comments = Thrift.copyList(args.comments, [Publish.Comment]);
    }
  }
};
Publish.GetCommentResp.prototype = {};
Publish.GetCommentResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size168 = 0;
        var _rtmp3172;
        this.comments = [];
        var _etype171 = 0;
        _rtmp3172 = input.readListBegin();
        _etype171 = _rtmp3172.etype;
        _size168 = _rtmp3172.size;
        for (var _i173 = 0; _i173 < _size168; ++_i173)
        {
          var elem174 = null;
          elem174 = new Publish.Comment();
          elem174.read(input);
          this.comments.push(elem174);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetCommentResp.prototype.write = function(output) {
  output.writeStructBegin('GetCommentResp');
  if (this.comments !== null && this.comments !== undefined) {
    output.writeFieldBegin('comments', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.comments.length);
    for (var iter175 in this.comments)
    {
      if (this.comments.hasOwnProperty(iter175))
      {
        iter175 = this.comments[iter175];
        iter175.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetCommentForEntityReq = function(args) {
  this.entityId = null;
  this.pageSize = null;
  this.pageState = null;
  if (args) {
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.pageSize !== undefined && args.pageSize !== null) {
      this.pageSize = args.pageSize;
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
Publish.GetCommentForEntityReq.prototype = {};
Publish.GetCommentForEntityReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I16) {
        this.pageSize = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetCommentForEntityReq.prototype.write = function(output) {
  output.writeStructBegin('GetCommentForEntityReq');
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 1);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.pageSize !== null && this.pageSize !== undefined) {
    output.writeFieldBegin('pageSize', Thrift.Type.I16, 2);
    output.writeI16(this.pageSize);
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 3);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Publish.GetCommentForEntityResp = function(args) {
  this.entityId = null;
  this.comments = null;
  this.pageState = null;
  if (args) {
    if (args.entityId !== undefined && args.entityId !== null) {
      this.entityId = args.entityId;
    }
    if (args.comments !== undefined && args.comments !== null) {
      this.comments = Thrift.copyList(args.comments, [Publish.Comment]);
    }
    if (args.pageState !== undefined && args.pageState !== null) {
      this.pageState = args.pageState;
    }
  }
};
Publish.GetCommentForEntityResp.prototype = {};
Publish.GetCommentForEntityResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.entityId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size176 = 0;
        var _rtmp3180;
        this.comments = [];
        var _etype179 = 0;
        _rtmp3180 = input.readListBegin();
        _etype179 = _rtmp3180.etype;
        _size176 = _rtmp3180.size;
        for (var _i181 = 0; _i181 < _size176; ++_i181)
        {
          var elem182 = null;
          elem182 = new Publish.Comment();
          elem182.read(input);
          this.comments.push(elem182);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.pageState = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Publish.GetCommentForEntityResp.prototype.write = function(output) {
  output.writeStructBegin('GetCommentForEntityResp');
  if (this.entityId !== null && this.entityId !== undefined) {
    output.writeFieldBegin('entityId', Thrift.Type.STRING, 1);
    output.writeString(this.entityId);
    output.writeFieldEnd();
  }
  if (this.comments !== null && this.comments !== undefined) {
    output.writeFieldBegin('comments', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.comments.length);
    for (var iter183 in this.comments)
    {
      if (this.comments.hasOwnProperty(iter183))
      {
        iter183 = this.comments[iter183];
        iter183.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.pageState !== null && this.pageState !== undefined) {
    output.writeFieldBegin('pageState', Thrift.Type.STRING, 3);
    output.writeString(this.pageState);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

