//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


if (typeof Web3 === 'undefined') {
  Web3 = {};
}
Web3.UserRegStatus = {
  'NO' : 0,
  'YES' : 1,
  'BANNED' : 2
};
Web3.IsUserRegisteredReq = function(args) {
  this.bcAddress = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
  }
};
Web3.IsUserRegisteredReq.prototype = {};
Web3.IsUserRegisteredReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.IsUserRegisteredReq.prototype.write = function(output) {
  output.writeStructBegin('IsUserRegisteredReq');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.IsUserRegisteredResp = function(args) {
  this.bcAddress = null;
  this.status = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
  }
};
Web3.IsUserRegisteredResp.prototype = {};
Web3.IsUserRegisteredResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.status = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.IsUserRegisteredResp.prototype.write = function(output) {
  output.writeStructBegin('IsUserRegisteredResp');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.BOOL, 2);
    output.writeBool(this.status);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.GetUserDataReq = function(args) {
  this.bcAddress = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
  }
};
Web3.GetUserDataReq.prototype = {};
Web3.GetUserDataReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.GetUserDataReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserDataReq');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.GetUserDataResp = function(args) {
  this.bcAddress = null;
  this.firstName = null;
  this.lastName = null;
  this.status = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
    if (args.firstName !== undefined && args.firstName !== null) {
      this.firstName = args.firstName;
    }
    if (args.lastName !== undefined && args.lastName !== null) {
      this.lastName = args.lastName;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
  }
};
Web3.GetUserDataResp.prototype = {};
Web3.GetUserDataResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.firstName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lastName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.GetUserDataResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserDataResp');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  if (this.firstName !== null && this.firstName !== undefined) {
    output.writeFieldBegin('firstName', Thrift.Type.STRING, 2);
    output.writeString(this.firstName);
    output.writeFieldEnd();
  }
  if (this.lastName !== null && this.lastName !== undefined) {
    output.writeFieldBegin('lastName', Thrift.Type.STRING, 3);
    output.writeString(this.lastName);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 4);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.GetUserBalanceReq = function(args) {
  this.bcAddress = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
  }
};
Web3.GetUserBalanceReq.prototype = {};
Web3.GetUserBalanceReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.GetUserBalanceReq.prototype.write = function(output) {
  output.writeStructBegin('GetUserBalanceReq');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.GetUserBalanceResp = function(args) {
  this.bcAddress = null;
  this.balance = null;
  if (args) {
    if (args.bcAddress !== undefined && args.bcAddress !== null) {
      this.bcAddress = args.bcAddress;
    }
    if (args.balance !== undefined && args.balance !== null) {
      this.balance = args.balance;
    }
  }
};
Web3.GetUserBalanceResp.prototype = {};
Web3.GetUserBalanceResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.balance = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.GetUserBalanceResp.prototype.write = function(output) {
  output.writeStructBegin('GetUserBalanceResp');
  if (this.bcAddress !== null && this.bcAddress !== undefined) {
    output.writeFieldBegin('bcAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcAddress);
    output.writeFieldEnd();
  }
  if (this.balance !== null && this.balance !== undefined) {
    output.writeFieldBegin('balance', Thrift.Type.STRING, 2);
    output.writeString(this.balance);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.SendEtherToUserReq = function(args) {
  this.bcSenderAddress = null;
  this.bcReceiverAddress = null;
  this.ether = null;
  if (args) {
    if (args.bcSenderAddress !== undefined && args.bcSenderAddress !== null) {
      this.bcSenderAddress = args.bcSenderAddress;
    }
    if (args.bcReceiverAddress !== undefined && args.bcReceiverAddress !== null) {
      this.bcReceiverAddress = args.bcReceiverAddress;
    }
    if (args.ether !== undefined && args.ether !== null) {
      this.ether = args.ether;
    }
  }
};
Web3.SendEtherToUserReq.prototype = {};
Web3.SendEtherToUserReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bcSenderAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.bcReceiverAddress = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.ether = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.SendEtherToUserReq.prototype.write = function(output) {
  output.writeStructBegin('SendEtherToUserReq');
  if (this.bcSenderAddress !== null && this.bcSenderAddress !== undefined) {
    output.writeFieldBegin('bcSenderAddress', Thrift.Type.STRING, 1);
    output.writeString(this.bcSenderAddress);
    output.writeFieldEnd();
  }
  if (this.bcReceiverAddress !== null && this.bcReceiverAddress !== undefined) {
    output.writeFieldBegin('bcReceiverAddress', Thrift.Type.STRING, 2);
    output.writeString(this.bcReceiverAddress);
    output.writeFieldEnd();
  }
  if (this.ether !== null && this.ether !== undefined) {
    output.writeFieldBegin('ether', Thrift.Type.I32, 3);
    output.writeI32(this.ether);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.SendEtherToUserResp = function(args) {
  this.transHash = null;
  this.err = null;
  if (args) {
    if (args.transHash !== undefined && args.transHash !== null) {
      this.transHash = args.transHash;
    }
    if (args.err !== undefined && args.err !== null) {
      this.err = args.err;
    }
  }
};
Web3.SendEtherToUserResp.prototype = {};
Web3.SendEtherToUserResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.transHash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.err = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.SendEtherToUserResp.prototype.write = function(output) {
  output.writeStructBegin('SendEtherToUserResp');
  if (this.transHash !== null && this.transHash !== undefined) {
    output.writeFieldBegin('transHash', Thrift.Type.STRING, 1);
    output.writeString(this.transHash);
    output.writeFieldEnd();
  }
  if (this.err !== null && this.err !== undefined) {
    output.writeFieldBegin('err', Thrift.Type.STRING, 2);
    output.writeString(this.err);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Web3.TestWeb3 = function(args) {
  this.testId = null;
  if (args) {
    if (args.testId !== undefined && args.testId !== null) {
      this.testId = args.testId;
    }
  }
};
Web3.TestWeb3.prototype = {};
Web3.TestWeb3.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I16) {
        this.testId = input.readI16().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Web3.TestWeb3.prototype.write = function(output) {
  output.writeStructBegin('TestWeb3');
  if (this.testId !== null && this.testId !== undefined) {
    output.writeFieldBegin('testId', Thrift.Type.I16, 1);
    output.writeI16(this.testId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

