//
// Autogenerated by Thrift Compiler (0.10.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


if (typeof Search === 'undefined') {
  Search = {};
}
Search.SearchDataType = {
  'KEYWORD' : 1,
  'AUTHOR' : 2,
  'ORG' : 3,
  'ASSIGNEE' : 4,
  'FOS' : 5,
  'CHANNEL' : 6,
  'TWEET_TEXT' : 7,
  'TYPE' : 8
};
Search.SearchOperation = {
  'MUST' : 1,
  'SHOULD' : 2,
  'MUST_NOT' : 3
};
Search.AggregationType = {
  'TWITTER_USER_ID' : 1,
  'TWITTER_TAGS' : 2,
  'FOS' : 3,
  'KEYWORDS' : 4,
  'YEAR' : 5,
  'AUTHORS' : 6
};
Search.Author = function(args) {
  this.name = null;
  this.org = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.org !== undefined && args.org !== null) {
      this.org = args.org;
    }
  }
};
Search.Author.prototype = {};
Search.Author.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.org = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.Author.prototype.write = function(output) {
  output.writeStructBegin('Author');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.org !== null && this.org !== undefined) {
    output.writeFieldBegin('org', Thrift.Type.STRING, 2);
    output.writeString(this.org);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.Document = function(args) {
  this.id = null;
  this.type = null;
  this.authors = null;
  this.title = null;
  this.summary = null;
  this.year = null;
  this.date = null;
  this.source = null;
  this.publisher = null;
  this.volume = null;
  this.issue = null;
  this.issn = null;
  this.isbn = null;
  this.doi = null;
  this.pageStart = null;
  this.pageEnd = null;
  this.lang = null;
  this.urls = null;
  this.keywords = null;
  this.references = null;
  this.fos = null;
  this.geohash = null;
  this.lemmas = null;
  this.claimedAuthorIds = null;
  this.commentIds = null;
  this.counters = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.authors !== undefined && args.authors !== null) {
      this.authors = Thrift.copyList(args.authors, [Search.Author]);
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.summary !== undefined && args.summary !== null) {
      this.summary = args.summary;
    }
    if (args.year !== undefined && args.year !== null) {
      this.year = args.year;
    }
    if (args.date !== undefined && args.date !== null) {
      this.date = args.date;
    }
    if (args.source !== undefined && args.source !== null) {
      this.source = args.source;
    }
    if (args.publisher !== undefined && args.publisher !== null) {
      this.publisher = args.publisher;
    }
    if (args.volume !== undefined && args.volume !== null) {
      this.volume = args.volume;
    }
    if (args.issue !== undefined && args.issue !== null) {
      this.issue = args.issue;
    }
    if (args.issn !== undefined && args.issn !== null) {
      this.issn = Thrift.copyList(args.issn, [null]);
    }
    if (args.isbn !== undefined && args.isbn !== null) {
      this.isbn = Thrift.copyList(args.isbn, [null]);
    }
    if (args.doi !== undefined && args.doi !== null) {
      this.doi = args.doi;
    }
    if (args.pageStart !== undefined && args.pageStart !== null) {
      this.pageStart = args.pageStart;
    }
    if (args.pageEnd !== undefined && args.pageEnd !== null) {
      this.pageEnd = args.pageEnd;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
    if (args.urls !== undefined && args.urls !== null) {
      this.urls = Thrift.copyList(args.urls, [null]);
    }
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [null]);
    }
    if (args.references !== undefined && args.references !== null) {
      this.references = Thrift.copyList(args.references, [null]);
    }
    if (args.fos !== undefined && args.fos !== null) {
      this.fos = Thrift.copyList(args.fos, [null]);
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = Thrift.copyList(args.geohash, [null]);
    }
    if (args.lemmas !== undefined && args.lemmas !== null) {
      this.lemmas = Thrift.copyList(args.lemmas, [null]);
    }
    if (args.claimedAuthorIds !== undefined && args.claimedAuthorIds !== null) {
      this.claimedAuthorIds = Thrift.copyList(args.claimedAuthorIds, [null]);
    }
    if (args.commentIds !== undefined && args.commentIds !== null) {
      this.commentIds = Thrift.copyList(args.commentIds, [null]);
    }
    if (args.counters !== undefined && args.counters !== null) {
      this.counters = new Publish.Counters(args.counters);
    }
  }
};
Search.Document.prototype = {};
Search.Document.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size0 = 0;
        var _rtmp34;
        this.authors = [];
        var _etype3 = 0;
        _rtmp34 = input.readListBegin();
        _etype3 = _rtmp34.etype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          var elem6 = null;
          elem6 = new Search.Author();
          elem6.read(input);
          this.authors.push(elem6);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.summary = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.year = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.date = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.source = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.publisher = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.volume = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.issue = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.LIST) {
        var _size7 = 0;
        var _rtmp311;
        this.issn = [];
        var _etype10 = 0;
        _rtmp311 = input.readListBegin();
        _etype10 = _rtmp311.etype;
        _size7 = _rtmp311.size;
        for (var _i12 = 0; _i12 < _size7; ++_i12)
        {
          var elem13 = null;
          elem13 = input.readString().value;
          this.issn.push(elem13);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.LIST) {
        var _size14 = 0;
        var _rtmp318;
        this.isbn = [];
        var _etype17 = 0;
        _rtmp318 = input.readListBegin();
        _etype17 = _rtmp318.etype;
        _size14 = _rtmp318.size;
        for (var _i19 = 0; _i19 < _size14; ++_i19)
        {
          var elem20 = null;
          elem20 = input.readString().value;
          this.isbn.push(elem20);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRING) {
        this.doi = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.I32) {
        this.pageStart = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.I32) {
        this.pageEnd = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.LIST) {
        var _size21 = 0;
        var _rtmp325;
        this.urls = [];
        var _etype24 = 0;
        _rtmp325 = input.readListBegin();
        _etype24 = _rtmp325.etype;
        _size21 = _rtmp325.size;
        for (var _i26 = 0; _i26 < _size21; ++_i26)
        {
          var elem27 = null;
          elem27 = input.readString().value;
          this.urls.push(elem27);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.LIST) {
        var _size28 = 0;
        var _rtmp332;
        this.keywords = [];
        var _etype31 = 0;
        _rtmp332 = input.readListBegin();
        _etype31 = _rtmp332.etype;
        _size28 = _rtmp332.size;
        for (var _i33 = 0; _i33 < _size28; ++_i33)
        {
          var elem34 = null;
          elem34 = input.readString().value;
          this.keywords.push(elem34);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.LIST) {
        var _size35 = 0;
        var _rtmp339;
        this.references = [];
        var _etype38 = 0;
        _rtmp339 = input.readListBegin();
        _etype38 = _rtmp339.etype;
        _size35 = _rtmp339.size;
        for (var _i40 = 0; _i40 < _size35; ++_i40)
        {
          var elem41 = null;
          elem41 = input.readString().value;
          this.references.push(elem41);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.LIST) {
        var _size42 = 0;
        var _rtmp346;
        this.fos = [];
        var _etype45 = 0;
        _rtmp346 = input.readListBegin();
        _etype45 = _rtmp346.etype;
        _size42 = _rtmp346.size;
        for (var _i47 = 0; _i47 < _size42; ++_i47)
        {
          var elem48 = null;
          elem48 = input.readString().value;
          this.fos.push(elem48);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.LIST) {
        var _size49 = 0;
        var _rtmp353;
        this.geohash = [];
        var _etype52 = 0;
        _rtmp353 = input.readListBegin();
        _etype52 = _rtmp353.etype;
        _size49 = _rtmp353.size;
        for (var _i54 = 0; _i54 < _size49; ++_i54)
        {
          var elem55 = null;
          elem55 = input.readString().value;
          this.geohash.push(elem55);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 23:
      if (ftype == Thrift.Type.LIST) {
        var _size56 = 0;
        var _rtmp360;
        this.lemmas = [];
        var _etype59 = 0;
        _rtmp360 = input.readListBegin();
        _etype59 = _rtmp360.etype;
        _size56 = _rtmp360.size;
        for (var _i61 = 0; _i61 < _size56; ++_i61)
        {
          var elem62 = null;
          elem62 = input.readString().value;
          this.lemmas.push(elem62);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 24:
      if (ftype == Thrift.Type.LIST) {
        var _size63 = 0;
        var _rtmp367;
        this.claimedAuthorIds = [];
        var _etype66 = 0;
        _rtmp367 = input.readListBegin();
        _etype66 = _rtmp367.etype;
        _size63 = _rtmp367.size;
        for (var _i68 = 0; _i68 < _size63; ++_i68)
        {
          var elem69 = null;
          elem69 = input.readString().value;
          this.claimedAuthorIds.push(elem69);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 25:
      if (ftype == Thrift.Type.LIST) {
        var _size70 = 0;
        var _rtmp374;
        this.commentIds = [];
        var _etype73 = 0;
        _rtmp374 = input.readListBegin();
        _etype73 = _rtmp374.etype;
        _size70 = _rtmp374.size;
        for (var _i75 = 0; _i75 < _size70; ++_i75)
        {
          var elem76 = null;
          elem76 = input.readString().value;
          this.commentIds.push(elem76);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 26:
      if (ftype == Thrift.Type.STRUCT) {
        this.counters = new Publish.Counters();
        this.counters.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.Document.prototype.write = function(output) {
  output.writeStructBegin('Document');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 2);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.authors !== null && this.authors !== undefined) {
    output.writeFieldBegin('authors', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.authors.length);
    for (var iter77 in this.authors)
    {
      if (this.authors.hasOwnProperty(iter77))
      {
        iter77 = this.authors[iter77];
        iter77.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 4);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  if (this.summary !== null && this.summary !== undefined) {
    output.writeFieldBegin('summary', Thrift.Type.STRING, 5);
    output.writeString(this.summary);
    output.writeFieldEnd();
  }
  if (this.year !== null && this.year !== undefined) {
    output.writeFieldBegin('year', Thrift.Type.STRING, 6);
    output.writeString(this.year);
    output.writeFieldEnd();
  }
  if (this.date !== null && this.date !== undefined) {
    output.writeFieldBegin('date', Thrift.Type.STRING, 7);
    output.writeString(this.date);
    output.writeFieldEnd();
  }
  if (this.source !== null && this.source !== undefined) {
    output.writeFieldBegin('source', Thrift.Type.STRING, 8);
    output.writeString(this.source);
    output.writeFieldEnd();
  }
  if (this.publisher !== null && this.publisher !== undefined) {
    output.writeFieldBegin('publisher', Thrift.Type.STRING, 9);
    output.writeString(this.publisher);
    output.writeFieldEnd();
  }
  if (this.volume !== null && this.volume !== undefined) {
    output.writeFieldBegin('volume', Thrift.Type.STRING, 10);
    output.writeString(this.volume);
    output.writeFieldEnd();
  }
  if (this.issue !== null && this.issue !== undefined) {
    output.writeFieldBegin('issue', Thrift.Type.STRING, 11);
    output.writeString(this.issue);
    output.writeFieldEnd();
  }
  if (this.issn !== null && this.issn !== undefined) {
    output.writeFieldBegin('issn', Thrift.Type.LIST, 12);
    output.writeListBegin(Thrift.Type.STRING, this.issn.length);
    for (var iter78 in this.issn)
    {
      if (this.issn.hasOwnProperty(iter78))
      {
        iter78 = this.issn[iter78];
        output.writeString(iter78);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.isbn !== null && this.isbn !== undefined) {
    output.writeFieldBegin('isbn', Thrift.Type.LIST, 13);
    output.writeListBegin(Thrift.Type.STRING, this.isbn.length);
    for (var iter79 in this.isbn)
    {
      if (this.isbn.hasOwnProperty(iter79))
      {
        iter79 = this.isbn[iter79];
        output.writeString(iter79);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.doi !== null && this.doi !== undefined) {
    output.writeFieldBegin('doi', Thrift.Type.STRING, 14);
    output.writeString(this.doi);
    output.writeFieldEnd();
  }
  if (this.pageStart !== null && this.pageStart !== undefined) {
    output.writeFieldBegin('pageStart', Thrift.Type.I32, 15);
    output.writeI32(this.pageStart);
    output.writeFieldEnd();
  }
  if (this.pageEnd !== null && this.pageEnd !== undefined) {
    output.writeFieldBegin('pageEnd', Thrift.Type.I32, 16);
    output.writeI32(this.pageEnd);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 17);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  if (this.urls !== null && this.urls !== undefined) {
    output.writeFieldBegin('urls', Thrift.Type.LIST, 18);
    output.writeListBegin(Thrift.Type.STRING, this.urls.length);
    for (var iter80 in this.urls)
    {
      if (this.urls.hasOwnProperty(iter80))
      {
        iter80 = this.urls[iter80];
        output.writeString(iter80);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 19);
    output.writeListBegin(Thrift.Type.STRING, this.keywords.length);
    for (var iter81 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter81))
      {
        iter81 = this.keywords[iter81];
        output.writeString(iter81);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.references !== null && this.references !== undefined) {
    output.writeFieldBegin('references', Thrift.Type.LIST, 20);
    output.writeListBegin(Thrift.Type.STRING, this.references.length);
    for (var iter82 in this.references)
    {
      if (this.references.hasOwnProperty(iter82))
      {
        iter82 = this.references[iter82];
        output.writeString(iter82);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.fos !== null && this.fos !== undefined) {
    output.writeFieldBegin('fos', Thrift.Type.LIST, 21);
    output.writeListBegin(Thrift.Type.STRING, this.fos.length);
    for (var iter83 in this.fos)
    {
      if (this.fos.hasOwnProperty(iter83))
      {
        iter83 = this.fos[iter83];
        output.writeString(iter83);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.LIST, 22);
    output.writeListBegin(Thrift.Type.STRING, this.geohash.length);
    for (var iter84 in this.geohash)
    {
      if (this.geohash.hasOwnProperty(iter84))
      {
        iter84 = this.geohash[iter84];
        output.writeString(iter84);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.lemmas !== null && this.lemmas !== undefined) {
    output.writeFieldBegin('lemmas', Thrift.Type.LIST, 23);
    output.writeListBegin(Thrift.Type.STRING, this.lemmas.length);
    for (var iter85 in this.lemmas)
    {
      if (this.lemmas.hasOwnProperty(iter85))
      {
        iter85 = this.lemmas[iter85];
        output.writeString(iter85);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.claimedAuthorIds !== null && this.claimedAuthorIds !== undefined) {
    output.writeFieldBegin('claimedAuthorIds', Thrift.Type.LIST, 24);
    output.writeListBegin(Thrift.Type.STRING, this.claimedAuthorIds.length);
    for (var iter86 in this.claimedAuthorIds)
    {
      if (this.claimedAuthorIds.hasOwnProperty(iter86))
      {
        iter86 = this.claimedAuthorIds[iter86];
        output.writeString(iter86);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.commentIds !== null && this.commentIds !== undefined) {
    output.writeFieldBegin('commentIds', Thrift.Type.LIST, 25);
    output.writeListBegin(Thrift.Type.STRING, this.commentIds.length);
    for (var iter87 in this.commentIds)
    {
      if (this.commentIds.hasOwnProperty(iter87))
      {
        iter87 = this.commentIds[iter87];
        output.writeString(iter87);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.counters !== null && this.counters !== undefined) {
    output.writeFieldBegin('counters', Thrift.Type.STRUCT, 26);
    this.counters.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.TwitterUser = function(args) {
  this.id = null;
  this.name = null;
  this.screenName = null;
  this.description = null;
  this.location = null;
  this.geohash = null;
  this.url = null;
  this.profileUrl = null;
  this.followersCount = null;
  this.friendsCount = null;
  this.listedCount = null;
  this.favouritesCount = null;
  this.statusesCount = null;
  this.createdTime = null;
  this.lang = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.screenName !== undefined && args.screenName !== null) {
      this.screenName = args.screenName;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = args.geohash;
    }
    if (args.url !== undefined && args.url !== null) {
      this.url = args.url;
    }
    if (args.profileUrl !== undefined && args.profileUrl !== null) {
      this.profileUrl = args.profileUrl;
    }
    if (args.followersCount !== undefined && args.followersCount !== null) {
      this.followersCount = args.followersCount;
    }
    if (args.friendsCount !== undefined && args.friendsCount !== null) {
      this.friendsCount = args.friendsCount;
    }
    if (args.listedCount !== undefined && args.listedCount !== null) {
      this.listedCount = args.listedCount;
    }
    if (args.favouritesCount !== undefined && args.favouritesCount !== null) {
      this.favouritesCount = args.favouritesCount;
    }
    if (args.statusesCount !== undefined && args.statusesCount !== null) {
      this.statusesCount = args.statusesCount;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
  }
};
Search.TwitterUser.prototype = {};
Search.TwitterUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.screenName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.geohash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.url = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.profileUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.followersCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.I32) {
        this.friendsCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.I32) {
        this.listedCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.I32) {
        this.favouritesCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.I32) {
        this.statusesCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.createdTime = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.TwitterUser.prototype.write = function(output) {
  output.writeStructBegin('TwitterUser');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.screenName !== null && this.screenName !== undefined) {
    output.writeFieldBegin('screenName', Thrift.Type.STRING, 3);
    output.writeString(this.screenName);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 4);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 5);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.STRING, 6);
    output.writeString(this.geohash);
    output.writeFieldEnd();
  }
  if (this.url !== null && this.url !== undefined) {
    output.writeFieldBegin('url', Thrift.Type.STRING, 7);
    output.writeString(this.url);
    output.writeFieldEnd();
  }
  if (this.profileUrl !== null && this.profileUrl !== undefined) {
    output.writeFieldBegin('profileUrl', Thrift.Type.STRING, 8);
    output.writeString(this.profileUrl);
    output.writeFieldEnd();
  }
  if (this.followersCount !== null && this.followersCount !== undefined) {
    output.writeFieldBegin('followersCount', Thrift.Type.I32, 9);
    output.writeI32(this.followersCount);
    output.writeFieldEnd();
  }
  if (this.friendsCount !== null && this.friendsCount !== undefined) {
    output.writeFieldBegin('friendsCount', Thrift.Type.I32, 11);
    output.writeI32(this.friendsCount);
    output.writeFieldEnd();
  }
  if (this.listedCount !== null && this.listedCount !== undefined) {
    output.writeFieldBegin('listedCount', Thrift.Type.I32, 12);
    output.writeI32(this.listedCount);
    output.writeFieldEnd();
  }
  if (this.favouritesCount !== null && this.favouritesCount !== undefined) {
    output.writeFieldBegin('favouritesCount', Thrift.Type.I32, 13);
    output.writeI32(this.favouritesCount);
    output.writeFieldEnd();
  }
  if (this.statusesCount !== null && this.statusesCount !== undefined) {
    output.writeFieldBegin('statusesCount', Thrift.Type.I32, 14);
    output.writeI32(this.statusesCount);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.STRING, 15);
    output.writeString(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 16);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.Tweet = function(args) {
  this.id = null;
  this.tweetText = null;
  this.source = null;
  this.location = null;
  this.geohash = null;
  this.lang = null;
  this.quoteCount = null;
  this.replyCount = null;
  this.retweetCount = null;
  this.favoriteCount = null;
  this.userId = null;
  this.retweetId = null;
  this.replyToStatusId = null;
  this.replyToUserId = null;
  this.replyToScreenName = null;
  this.createdTime = null;
  this.channels = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.tweetText !== undefined && args.tweetText !== null) {
      this.tweetText = args.tweetText;
    }
    if (args.source !== undefined && args.source !== null) {
      this.source = args.source;
    }
    if (args.location !== undefined && args.location !== null) {
      this.location = args.location;
    }
    if (args.geohash !== undefined && args.geohash !== null) {
      this.geohash = args.geohash;
    }
    if (args.lang !== undefined && args.lang !== null) {
      this.lang = args.lang;
    }
    if (args.quoteCount !== undefined && args.quoteCount !== null) {
      this.quoteCount = args.quoteCount;
    }
    if (args.replyCount !== undefined && args.replyCount !== null) {
      this.replyCount = args.replyCount;
    }
    if (args.retweetCount !== undefined && args.retweetCount !== null) {
      this.retweetCount = args.retweetCount;
    }
    if (args.favoriteCount !== undefined && args.favoriteCount !== null) {
      this.favoriteCount = args.favoriteCount;
    }
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    }
    if (args.retweetId !== undefined && args.retweetId !== null) {
      this.retweetId = args.retweetId;
    }
    if (args.replyToStatusId !== undefined && args.replyToStatusId !== null) {
      this.replyToStatusId = args.replyToStatusId;
    }
    if (args.replyToUserId !== undefined && args.replyToUserId !== null) {
      this.replyToUserId = args.replyToUserId;
    }
    if (args.replyToScreenName !== undefined && args.replyToScreenName !== null) {
      this.replyToScreenName = args.replyToScreenName;
    }
    if (args.createdTime !== undefined && args.createdTime !== null) {
      this.createdTime = args.createdTime;
    }
    if (args.channels !== undefined && args.channels !== null) {
      this.channels = Thrift.copyList(args.channels, [null]);
    }
  }
};
Search.Tweet.prototype = {};
Search.Tweet.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.tweetText = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.source = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.location = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.geohash = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.lang = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.quoteCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.replyCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.retweetCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I32) {
        this.favoriteCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.retweetId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.replyToStatusId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.STRING) {
        this.replyToUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.STRING) {
        this.replyToScreenName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.createdTime = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.LIST) {
        var _size88 = 0;
        var _rtmp392;
        this.channels = [];
        var _etype91 = 0;
        _rtmp392 = input.readListBegin();
        _etype91 = _rtmp392.etype;
        _size88 = _rtmp392.size;
        for (var _i93 = 0; _i93 < _size88; ++_i93)
        {
          var elem94 = null;
          elem94 = input.readString().value;
          this.channels.push(elem94);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.Tweet.prototype.write = function(output) {
  output.writeStructBegin('Tweet');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.STRING, 1);
    output.writeString(this.id);
    output.writeFieldEnd();
  }
  if (this.tweetText !== null && this.tweetText !== undefined) {
    output.writeFieldBegin('tweetText', Thrift.Type.STRING, 2);
    output.writeString(this.tweetText);
    output.writeFieldEnd();
  }
  if (this.source !== null && this.source !== undefined) {
    output.writeFieldBegin('source', Thrift.Type.STRING, 3);
    output.writeString(this.source);
    output.writeFieldEnd();
  }
  if (this.location !== null && this.location !== undefined) {
    output.writeFieldBegin('location', Thrift.Type.STRING, 4);
    output.writeString(this.location);
    output.writeFieldEnd();
  }
  if (this.geohash !== null && this.geohash !== undefined) {
    output.writeFieldBegin('geohash', Thrift.Type.STRING, 5);
    output.writeString(this.geohash);
    output.writeFieldEnd();
  }
  if (this.lang !== null && this.lang !== undefined) {
    output.writeFieldBegin('lang', Thrift.Type.STRING, 6);
    output.writeString(this.lang);
    output.writeFieldEnd();
  }
  if (this.quoteCount !== null && this.quoteCount !== undefined) {
    output.writeFieldBegin('quoteCount', Thrift.Type.I32, 7);
    output.writeI32(this.quoteCount);
    output.writeFieldEnd();
  }
  if (this.replyCount !== null && this.replyCount !== undefined) {
    output.writeFieldBegin('replyCount', Thrift.Type.I32, 8);
    output.writeI32(this.replyCount);
    output.writeFieldEnd();
  }
  if (this.retweetCount !== null && this.retweetCount !== undefined) {
    output.writeFieldBegin('retweetCount', Thrift.Type.I32, 9);
    output.writeI32(this.retweetCount);
    output.writeFieldEnd();
  }
  if (this.favoriteCount !== null && this.favoriteCount !== undefined) {
    output.writeFieldBegin('favoriteCount', Thrift.Type.I32, 10);
    output.writeI32(this.favoriteCount);
    output.writeFieldEnd();
  }
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 11);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.retweetId !== null && this.retweetId !== undefined) {
    output.writeFieldBegin('retweetId', Thrift.Type.STRING, 12);
    output.writeString(this.retweetId);
    output.writeFieldEnd();
  }
  if (this.replyToStatusId !== null && this.replyToStatusId !== undefined) {
    output.writeFieldBegin('replyToStatusId', Thrift.Type.STRING, 13);
    output.writeString(this.replyToStatusId);
    output.writeFieldEnd();
  }
  if (this.replyToUserId !== null && this.replyToUserId !== undefined) {
    output.writeFieldBegin('replyToUserId', Thrift.Type.STRING, 14);
    output.writeString(this.replyToUserId);
    output.writeFieldEnd();
  }
  if (this.replyToScreenName !== null && this.replyToScreenName !== undefined) {
    output.writeFieldBegin('replyToScreenName', Thrift.Type.STRING, 15);
    output.writeString(this.replyToScreenName);
    output.writeFieldEnd();
  }
  if (this.createdTime !== null && this.createdTime !== undefined) {
    output.writeFieldBegin('createdTime', Thrift.Type.STRING, 16);
    output.writeString(this.createdTime);
    output.writeFieldEnd();
  }
  if (this.channels !== null && this.channels !== undefined) {
    output.writeFieldBegin('channels', Thrift.Type.LIST, 17);
    output.writeListBegin(Thrift.Type.STRING, this.channels.length);
    for (var iter95 in this.channels)
    {
      if (this.channels.hasOwnProperty(iter95))
      {
        iter95 = this.channels[iter95];
        output.writeString(iter95);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.Range = function(args) {
  this.type = null;
  this.gte = null;
  this.lte = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.gte !== undefined && args.gte !== null) {
      this.gte = args.gte;
    }
    if (args.lte !== undefined && args.lte !== null) {
      this.lte = args.lte;
    }
  }
};
Search.Range.prototype = {};
Search.Range.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.gte = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.lte = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.Range.prototype.write = function(output) {
  output.writeStructBegin('Range');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 1);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.gte !== null && this.gte !== undefined) {
    output.writeFieldBegin('gte', Thrift.Type.STRING, 2);
    output.writeString(this.gte);
    output.writeFieldEnd();
  }
  if (this.lte !== null && this.lte !== undefined) {
    output.writeFieldBegin('lte', Thrift.Type.STRING, 3);
    output.writeString(this.lte);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.GeohashRange = function(args) {
  this.tl = null;
  this.br = null;
  if (args) {
    if (args.tl !== undefined && args.tl !== null) {
      this.tl = args.tl;
    }
    if (args.br !== undefined && args.br !== null) {
      this.br = args.br;
    }
  }
};
Search.GeohashRange.prototype = {};
Search.GeohashRange.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.tl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.br = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.GeohashRange.prototype.write = function(output) {
  output.writeStructBegin('GeohashRange');
  if (this.tl !== null && this.tl !== undefined) {
    output.writeFieldBegin('tl', Thrift.Type.STRING, 1);
    output.writeString(this.tl);
    output.writeFieldEnd();
  }
  if (this.br !== null && this.br !== undefined) {
    output.writeFieldBegin('br', Thrift.Type.STRING, 2);
    output.writeString(this.br);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.SearchData = function(args) {
  this.value = null;
  this.type = null;
  this.operation = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.operation !== undefined && args.operation !== null) {
      this.operation = args.operation;
    }
  }
};
Search.SearchData.prototype = {};
Search.SearchData.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.operation = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.SearchData.prototype.write = function(output) {
  output.writeStructBegin('SearchData');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.operation !== null && this.operation !== undefined) {
    output.writeFieldBegin('operation', Thrift.Type.I32, 3);
    output.writeI32(this.operation);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.SearchEsReq = function(args) {
  this.indexName = null;
  this.start = null;
  this.size = null;
  this.sortType = null;
  this.sortOrder = null;
  this.range = null;
  this.searchType = null;
  this.geohashRange = null;
  this.search = null;
  this.aggregation = null;
  if (args) {
    if (args.indexName !== undefined && args.indexName !== null) {
      this.indexName = args.indexName;
    }
    if (args.start !== undefined && args.start !== null) {
      this.start = args.start;
    }
    if (args.size !== undefined && args.size !== null) {
      this.size = args.size;
    }
    if (args.sortType !== undefined && args.sortType !== null) {
      this.sortType = args.sortType;
    }
    if (args.sortOrder !== undefined && args.sortOrder !== null) {
      this.sortOrder = args.sortOrder;
    }
    if (args.range !== undefined && args.range !== null) {
      this.range = new Search.Range(args.range);
    }
    if (args.searchType !== undefined && args.searchType !== null) {
      this.searchType = args.searchType;
    }
    if (args.geohashRange !== undefined && args.geohashRange !== null) {
      this.geohashRange = new Search.GeohashRange(args.geohashRange);
    }
    if (args.search !== undefined && args.search !== null) {
      this.search = Thrift.copyList(args.search, [Search.SearchData]);
    }
    if (args.aggregation !== undefined && args.aggregation !== null) {
      this.aggregation = Thrift.copyList(args.aggregation, [null]);
    }
  }
};
Search.SearchEsReq.prototype = {};
Search.SearchEsReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.indexName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.start = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.size = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.sortType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.sortOrder = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.range = new Search.Range();
        this.range.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.searchType = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.geohashRange = new Search.GeohashRange();
        this.geohashRange.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        var _size96 = 0;
        var _rtmp3100;
        this.search = [];
        var _etype99 = 0;
        _rtmp3100 = input.readListBegin();
        _etype99 = _rtmp3100.etype;
        _size96 = _rtmp3100.size;
        for (var _i101 = 0; _i101 < _size96; ++_i101)
        {
          var elem102 = null;
          elem102 = new Search.SearchData();
          elem102.read(input);
          this.search.push(elem102);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.LIST) {
        var _size103 = 0;
        var _rtmp3107;
        this.aggregation = [];
        var _etype106 = 0;
        _rtmp3107 = input.readListBegin();
        _etype106 = _rtmp3107.etype;
        _size103 = _rtmp3107.size;
        for (var _i108 = 0; _i108 < _size103; ++_i108)
        {
          var elem109 = null;
          elem109 = input.readI32().value;
          this.aggregation.push(elem109);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.SearchEsReq.prototype.write = function(output) {
  output.writeStructBegin('SearchEsReq');
  if (this.indexName !== null && this.indexName !== undefined) {
    output.writeFieldBegin('indexName', Thrift.Type.STRING, 1);
    output.writeString(this.indexName);
    output.writeFieldEnd();
  }
  if (this.start !== null && this.start !== undefined) {
    output.writeFieldBegin('start', Thrift.Type.I32, 2);
    output.writeI32(this.start);
    output.writeFieldEnd();
  }
  if (this.size !== null && this.size !== undefined) {
    output.writeFieldBegin('size', Thrift.Type.I32, 3);
    output.writeI32(this.size);
    output.writeFieldEnd();
  }
  if (this.sortType !== null && this.sortType !== undefined) {
    output.writeFieldBegin('sortType', Thrift.Type.STRING, 4);
    output.writeString(this.sortType);
    output.writeFieldEnd();
  }
  if (this.sortOrder !== null && this.sortOrder !== undefined) {
    output.writeFieldBegin('sortOrder', Thrift.Type.STRING, 5);
    output.writeString(this.sortOrder);
    output.writeFieldEnd();
  }
  if (this.range !== null && this.range !== undefined) {
    output.writeFieldBegin('range', Thrift.Type.STRUCT, 6);
    this.range.write(output);
    output.writeFieldEnd();
  }
  if (this.searchType !== null && this.searchType !== undefined) {
    output.writeFieldBegin('searchType', Thrift.Type.STRING, 7);
    output.writeString(this.searchType);
    output.writeFieldEnd();
  }
  if (this.geohashRange !== null && this.geohashRange !== undefined) {
    output.writeFieldBegin('geohashRange', Thrift.Type.STRUCT, 8);
    this.geohashRange.write(output);
    output.writeFieldEnd();
  }
  if (this.search !== null && this.search !== undefined) {
    output.writeFieldBegin('search', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRUCT, this.search.length);
    for (var iter110 in this.search)
    {
      if (this.search.hasOwnProperty(iter110))
      {
        iter110 = this.search[iter110];
        iter110.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.aggregation !== null && this.aggregation !== undefined) {
    output.writeFieldBegin('aggregation', Thrift.Type.LIST, 10);
    output.writeListBegin(Thrift.Type.I32, this.aggregation.length);
    for (var iter111 in this.aggregation)
    {
      if (this.aggregation.hasOwnProperty(iter111))
      {
        iter111 = this.aggregation[iter111];
        output.writeI32(iter111);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.AggregationKey = function(args) {
  this.key = null;
  this.docCount = null;
  if (args) {
    if (args.key !== undefined && args.key !== null) {
      this.key = args.key;
    }
    if (args.docCount !== undefined && args.docCount !== null) {
      this.docCount = args.docCount;
    }
  }
};
Search.AggregationKey.prototype = {};
Search.AggregationKey.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.key = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.docCount = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.AggregationKey.prototype.write = function(output) {
  output.writeStructBegin('AggregationKey');
  if (this.key !== null && this.key !== undefined) {
    output.writeFieldBegin('key', Thrift.Type.STRING, 1);
    output.writeString(this.key);
    output.writeFieldEnd();
  }
  if (this.docCount !== null && this.docCount !== undefined) {
    output.writeFieldBegin('docCount', Thrift.Type.I32, 2);
    output.writeI32(this.docCount);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.AggregationResult = function(args) {
  this.type = null;
  this.keys = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.keys !== undefined && args.keys !== null) {
      this.keys = Thrift.copyList(args.keys, [Search.AggregationKey]);
    }
  }
};
Search.AggregationResult.prototype = {};
Search.AggregationResult.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size112 = 0;
        var _rtmp3116;
        this.keys = [];
        var _etype115 = 0;
        _rtmp3116 = input.readListBegin();
        _etype115 = _rtmp3116.etype;
        _size112 = _rtmp3116.size;
        for (var _i117 = 0; _i117 < _size112; ++_i117)
        {
          var elem118 = null;
          elem118 = new Search.AggregationKey();
          elem118.read(input);
          this.keys.push(elem118);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.AggregationResult.prototype.write = function(output) {
  output.writeStructBegin('AggregationResult');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.keys !== null && this.keys !== undefined) {
    output.writeFieldBegin('keys', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.keys.length);
    for (var iter119 in this.keys)
    {
      if (this.keys.hasOwnProperty(iter119))
      {
        iter119 = this.keys[iter119];
        iter119.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.SearchEsResp = function(args) {
  this.documents = null;
  this.total = null;
  this.start = null;
  this.tweets = null;
  this.twitterUsers = null;
  this.aggResults = null;
  if (args) {
    if (args.documents !== undefined && args.documents !== null) {
      this.documents = Thrift.copyList(args.documents, [Search.Document]);
    }
    if (args.total !== undefined && args.total !== null) {
      this.total = args.total;
    }
    if (args.start !== undefined && args.start !== null) {
      this.start = args.start;
    }
    if (args.tweets !== undefined && args.tweets !== null) {
      this.tweets = Thrift.copyList(args.tweets, [Search.Tweet]);
    }
    if (args.twitterUsers !== undefined && args.twitterUsers !== null) {
      this.twitterUsers = Thrift.copyList(args.twitterUsers, [Search.TwitterUser]);
    }
    if (args.aggResults !== undefined && args.aggResults !== null) {
      this.aggResults = Thrift.copyList(args.aggResults, [Search.AggregationResult]);
    }
  }
};
Search.SearchEsResp.prototype = {};
Search.SearchEsResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size120 = 0;
        var _rtmp3124;
        this.documents = [];
        var _etype123 = 0;
        _rtmp3124 = input.readListBegin();
        _etype123 = _rtmp3124.etype;
        _size120 = _rtmp3124.size;
        for (var _i125 = 0; _i125 < _size120; ++_i125)
        {
          var elem126 = null;
          elem126 = new Search.Document();
          elem126.read(input);
          this.documents.push(elem126);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.total = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.start = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        var _size127 = 0;
        var _rtmp3131;
        this.tweets = [];
        var _etype130 = 0;
        _rtmp3131 = input.readListBegin();
        _etype130 = _rtmp3131.etype;
        _size127 = _rtmp3131.size;
        for (var _i132 = 0; _i132 < _size127; ++_i132)
        {
          var elem133 = null;
          elem133 = new Search.Tweet();
          elem133.read(input);
          this.tweets.push(elem133);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        var _size134 = 0;
        var _rtmp3138;
        this.twitterUsers = [];
        var _etype137 = 0;
        _rtmp3138 = input.readListBegin();
        _etype137 = _rtmp3138.etype;
        _size134 = _rtmp3138.size;
        for (var _i139 = 0; _i139 < _size134; ++_i139)
        {
          var elem140 = null;
          elem140 = new Search.TwitterUser();
          elem140.read(input);
          this.twitterUsers.push(elem140);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        var _size141 = 0;
        var _rtmp3145;
        this.aggResults = [];
        var _etype144 = 0;
        _rtmp3145 = input.readListBegin();
        _etype144 = _rtmp3145.etype;
        _size141 = _rtmp3145.size;
        for (var _i146 = 0; _i146 < _size141; ++_i146)
        {
          var elem147 = null;
          elem147 = new Search.AggregationResult();
          elem147.read(input);
          this.aggResults.push(elem147);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.SearchEsResp.prototype.write = function(output) {
  output.writeStructBegin('SearchEsResp');
  if (this.documents !== null && this.documents !== undefined) {
    output.writeFieldBegin('documents', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents.length);
    for (var iter148 in this.documents)
    {
      if (this.documents.hasOwnProperty(iter148))
      {
        iter148 = this.documents[iter148];
        iter148.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.total !== null && this.total !== undefined) {
    output.writeFieldBegin('total', Thrift.Type.I32, 2);
    output.writeI32(this.total);
    output.writeFieldEnd();
  }
  if (this.start !== null && this.start !== undefined) {
    output.writeFieldBegin('start', Thrift.Type.I32, 3);
    output.writeI32(this.start);
    output.writeFieldEnd();
  }
  if (this.tweets !== null && this.tweets !== undefined) {
    output.writeFieldBegin('tweets', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.tweets.length);
    for (var iter149 in this.tweets)
    {
      if (this.tweets.hasOwnProperty(iter149))
      {
        iter149 = this.tweets[iter149];
        iter149.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.twitterUsers !== null && this.twitterUsers !== undefined) {
    output.writeFieldBegin('twitterUsers', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.twitterUsers.length);
    for (var iter150 in this.twitterUsers)
    {
      if (this.twitterUsers.hasOwnProperty(iter150))
      {
        iter150 = this.twitterUsers[iter150];
        iter150.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.aggResults !== null && this.aggResults !== undefined) {
    output.writeFieldBegin('aggResults', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.aggResults.length);
    for (var iter151 in this.aggResults)
    {
      if (this.aggResults.hasOwnProperty(iter151))
      {
        iter151 = this.aggResults[iter151];
        iter151.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.GetEsReq = function(args) {
  this.indexName = null;
  this.type = null;
  this.ids = null;
  if (args) {
    if (args.indexName !== undefined && args.indexName !== null) {
      this.indexName = args.indexName;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.ids !== undefined && args.ids !== null) {
      this.ids = Thrift.copyList(args.ids, [null]);
    }
  }
};
Search.GetEsReq.prototype = {};
Search.GetEsReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.indexName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.type = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size152 = 0;
        var _rtmp3156;
        this.ids = [];
        var _etype155 = 0;
        _rtmp3156 = input.readListBegin();
        _etype155 = _rtmp3156.etype;
        _size152 = _rtmp3156.size;
        for (var _i157 = 0; _i157 < _size152; ++_i157)
        {
          var elem158 = null;
          elem158 = input.readString().value;
          this.ids.push(elem158);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.GetEsReq.prototype.write = function(output) {
  output.writeStructBegin('GetEsReq');
  if (this.indexName !== null && this.indexName !== undefined) {
    output.writeFieldBegin('indexName', Thrift.Type.STRING, 1);
    output.writeString(this.indexName);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.STRING, 2);
    output.writeString(this.type);
    output.writeFieldEnd();
  }
  if (this.ids !== null && this.ids !== undefined) {
    output.writeFieldBegin('ids', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRING, this.ids.length);
    for (var iter159 in this.ids)
    {
      if (this.ids.hasOwnProperty(iter159))
      {
        iter159 = this.ids[iter159];
        output.writeString(iter159);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.GetEsResp = function(args) {
  this.documents = null;
  this.tweets = null;
  this.twitterUsers = null;
  if (args) {
    if (args.documents !== undefined && args.documents !== null) {
      this.documents = Thrift.copyList(args.documents, [Search.Document]);
    }
    if (args.tweets !== undefined && args.tweets !== null) {
      this.tweets = Thrift.copyList(args.tweets, [Search.Tweet]);
    }
    if (args.twitterUsers !== undefined && args.twitterUsers !== null) {
      this.twitterUsers = Thrift.copyList(args.twitterUsers, [Search.TwitterUser]);
    }
  }
};
Search.GetEsResp.prototype = {};
Search.GetEsResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size160 = 0;
        var _rtmp3164;
        this.documents = [];
        var _etype163 = 0;
        _rtmp3164 = input.readListBegin();
        _etype163 = _rtmp3164.etype;
        _size160 = _rtmp3164.size;
        for (var _i165 = 0; _i165 < _size160; ++_i165)
        {
          var elem166 = null;
          elem166 = new Search.Document();
          elem166.read(input);
          this.documents.push(elem166);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        var _size167 = 0;
        var _rtmp3171;
        this.tweets = [];
        var _etype170 = 0;
        _rtmp3171 = input.readListBegin();
        _etype170 = _rtmp3171.etype;
        _size167 = _rtmp3171.size;
        for (var _i172 = 0; _i172 < _size167; ++_i172)
        {
          var elem173 = null;
          elem173 = new Search.Tweet();
          elem173.read(input);
          this.tweets.push(elem173);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        var _size174 = 0;
        var _rtmp3178;
        this.twitterUsers = [];
        var _etype177 = 0;
        _rtmp3178 = input.readListBegin();
        _etype177 = _rtmp3178.etype;
        _size174 = _rtmp3178.size;
        for (var _i179 = 0; _i179 < _size174; ++_i179)
        {
          var elem180 = null;
          elem180 = new Search.TwitterUser();
          elem180.read(input);
          this.twitterUsers.push(elem180);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.GetEsResp.prototype.write = function(output) {
  output.writeStructBegin('GetEsResp');
  if (this.documents !== null && this.documents !== undefined) {
    output.writeFieldBegin('documents', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents.length);
    for (var iter181 in this.documents)
    {
      if (this.documents.hasOwnProperty(iter181))
      {
        iter181 = this.documents[iter181];
        iter181.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.tweets !== null && this.tweets !== undefined) {
    output.writeFieldBegin('tweets', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.tweets.length);
    for (var iter182 in this.tweets)
    {
      if (this.tweets.hasOwnProperty(iter182))
      {
        iter182 = this.tweets[iter182];
        iter182.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.twitterUsers !== null && this.twitterUsers !== undefined) {
    output.writeFieldBegin('twitterUsers', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.twitterUsers.length);
    for (var iter183 in this.twitterUsers)
    {
      if (this.twitterUsers.hasOwnProperty(iter183))
      {
        iter183 = this.twitterUsers[iter183];
        iter183.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.SearchVocabularyReq = function(args) {
  this.value = null;
  this.type = null;
  this.top = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.top !== undefined && args.top !== null) {
      this.top = args.top;
    }
  }
};
Search.SearchVocabularyReq.prototype = {};
Search.SearchVocabularyReq.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.top = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.SearchVocabularyReq.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabularyReq');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.top !== null && this.top !== undefined) {
    output.writeFieldBegin('top', Thrift.Type.I32, 4);
    output.writeI32(this.top);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.VocabularyKeyword = function(args) {
  this.name = null;
  this.freq = null;
  this.files = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.freq !== undefined && args.freq !== null) {
      this.freq = args.freq;
    }
    if (args.files !== undefined && args.files !== null) {
      this.files = args.files;
    }
  }
};
Search.VocabularyKeyword.prototype = {};
Search.VocabularyKeyword.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.freq = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.files = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.VocabularyKeyword.prototype.write = function(output) {
  output.writeStructBegin('VocabularyKeyword');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.freq !== null && this.freq !== undefined) {
    output.writeFieldBegin('freq', Thrift.Type.I32, 2);
    output.writeI32(this.freq);
    output.writeFieldEnd();
  }
  if (this.files !== null && this.files !== undefined) {
    output.writeFieldBegin('files', Thrift.Type.I32, 3);
    output.writeI32(this.files);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

Search.SearchVocabularyResp = function(args) {
  this.keywords = null;
  if (args) {
    if (args.keywords !== undefined && args.keywords !== null) {
      this.keywords = Thrift.copyList(args.keywords, [Search.VocabularyKeyword]);
    }
  }
};
Search.SearchVocabularyResp.prototype = {};
Search.SearchVocabularyResp.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        var _size184 = 0;
        var _rtmp3188;
        this.keywords = [];
        var _etype187 = 0;
        _rtmp3188 = input.readListBegin();
        _etype187 = _rtmp3188.etype;
        _size184 = _rtmp3188.size;
        for (var _i189 = 0; _i189 < _size184; ++_i189)
        {
          var elem190 = null;
          elem190 = new Search.VocabularyKeyword();
          elem190.read(input);
          this.keywords.push(elem190);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Search.SearchVocabularyResp.prototype.write = function(output) {
  output.writeStructBegin('SearchVocabularyResp');
  if (this.keywords !== null && this.keywords !== undefined) {
    output.writeFieldBegin('keywords', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.keywords.length);
    for (var iter191 in this.keywords)
    {
      if (this.keywords.hasOwnProperty(iter191))
      {
        iter191 = this.keywords[iter191];
        iter191.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

